/* eslint-disable no-console */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { CommonGetDynamicFormLookupCommand, UserSecurityFunctionDto } from '@verde/api';
import { UserService } from '@verde/core';
import { DotsMenuItem, SidePanelWidth, WebDynamicFormMode, WebDynamicFormType, WebDynamicService, WebDynamicSidePanelArgs } from '@verde/shared';
import { Observable, of, Subject } from 'rxjs';
import { catchError, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

interface GridProps extends FormlyFieldProps {
  loading?: boolean;
  lookUp?: CommonGetDynamicFormLookupCommand;
  dynamicgroup?: string;
}

@Component({
  selector: 'verde-kendo-formly-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoFormlyGrid extends FieldType<FieldTypeConfig<GridProps>> implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  private refreshOptions$ = new Subject<void>();
  options$: Observable<any[]>;
  sort: SortDescriptor[];
  dotMenuItems: DotsMenuItem[] = [{}];
  functions: Array<UserSecurityFunctionDto> = new Array<UserSecurityFunctionDto>();
  selectedMenuItem: any;

  constructor(private cd: ChangeDetectorRef, private userService: UserService, private webDynamicService: WebDynamicService) {
    super();
  }

  ngOnInit() {
    this.options$ = this.refreshOptions$.pipe(
      startWith([]), // Initial call when component initializes
      switchMap(() => {
        if (this.props.options instanceof Observable) {
          this.props.loading = true; // Set loading state
          return this.props.options.pipe(
            tap(() => {
              this.props.loading = false;
              this.getThreeDotMenuFunctions();
              this.cd.detectChanges();
            }),
            catchError((error) => {
              console.error('Error loading options:', error);
              this.props.loading = false;
              this.cd.detectChanges();
              return of([]); // Return an empty array on error
            }),
            takeUntil(this.onDestroy$),
          );
        }
      }),
    );

    if (this.props.lookUp.sort) {
      this.sort = this.props.lookUp.sort as SortDescriptor[];
    }
  }

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  isObjectWithName(value: any): boolean {
    return value && typeof value === 'object' && 'name' in value;
  }

  isDateString(value: string): boolean {
    const date = new Date(value);
    const isDate = !isNaN(date.getTime());
    return isDate;
  }

  convertToDate(value: string): Date {
    return new Date(value);
  }

  updateDateValue(dataItem: any, field: string, newValue: Date): void {
    dataItem[field] = newValue.toISOString(); // or format it as needed
  }

  getThreeDotMenuFunctions() {
    console.log('DYNAMIC GROUPS', this.props.dynamicgroup);

    this.functions = [];

    const initialFunctions = this.userService.filterDynamicGroup(this.props.dynamicgroup, this.userService.user.legalEntityId).sort((a, b) => {
      if (a.menuName && b.menuName) {
        const nameA = a.menuName.toLowerCase();
        const nameB = b.menuName.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }

      return 0;
    });

    initialFunctions.forEach((t) => {
      this.functions.push(t);
    });

    console.log('FUNCTIONS', this.functions);
    this.cd.detectChanges();
  }

  refresh() {
    this.refreshOptions$.next(); // This will trigger the API call again
  }

  menuClicked(selectedMenuItem: any) {
    //this.webDynamicService.setDynamicForm({
    //  entityId: this.userService?.user?.employeeId,
    //  formFile: 'example-cross-filter',
    //  formType: WebDynamicFormType.SIDEPANEL,
    //  formMode: WebDynamicFormMode.UPDATE,
    //  args: {
    //    visible: true,
    //    size: SidePanelWidth.Half,
    //  } as WebDynamicSidePanelArgs,
    //});

    // this.selectedMenuItem = selectedMenuItem;
    // this.dotMenuItems = [];
    // const tempItems: DotsMenuItem[] = [];
    // this.functions.forEach((t) => {
    //   if (t.technicalName === 'PEOPLE-GRID-LINE-MENU-EDIT-POSITION-SEATS') {
    //     tempItems.push({ id: t.technicalName, text: t.menuName, jsonFileName: t.jsonFileName?.toString() });
    //   }
    // });
    // this.dotMenuItems = [...tempItems];
    this.dotMenuItems = [];
    this.functions.forEach((t) => {
      if (t.bt_GridLine == true) {
        this.dotMenuItems.push({
          id: t.menuName,
          text: t.menuName,
          jsonFileName: t.jsonFileName,
          items: [],
          disabled: false,
          cssStyle: '',
        });
      }
    });
  }

  menuItemClicked(menuItem: DotsMenuItem) {
    if (menuItem.id === 'PEOPLE-GRID-LINE-MENU-EDIT-POSITION-SEATS') {
      this.webDynamicService.setDynamicForm({
        formFile: menuItem.jsonFileName,
        formType: WebDynamicFormType.SIDEPANEL,
        args: {
          visible: true,
          size: SidePanelWidth.Half,
          model: {
            entityId: this.selectedMenuItem.bt_verdestructurechangerequestid,
          },
        } as WebDynamicSidePanelArgs,
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  buttonClicked(button) {
    console.log(button);
  }
}
