/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BookingsBaseService } from '../bookings-base-service';
import { BookingsApiConfiguration } from '../bookings-api-configuration';
import { BookingsStrictHttpResponse } from '../bookings-strict-http-response';
import { BookingsRequestBuilder } from '../bookings-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BookingsBookingsFilteredDto } from '../models/bookings-bookings-filtered-dto';
import { BookingsGetBookingsByUserIdQuery } from '../models/bookings-get-bookings-by-user-id-query';
import { BookingsGetBookingsSummaryQuery } from '../models/bookings-get-bookings-summary-query';
import { BookingsPatchBookingCommand } from '../models/bookings-patch-booking-command';
import { BookingsPostBookingsCommand } from '../models/bookings-post-bookings-command';

@Injectable({
  providedIn: 'root',
})
export class BookingsApiBookingsService extends BookingsBaseService {
  constructor(config: BookingsApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation bookingsSummary
   */
  static readonly BookingsSummaryPath = '/api/Bookings/get/bookingSummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookingsSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookingsSummary$Response(params?: { body?: BookingsGetBookingsSummaryQuery }): Observable<BookingsStrictHttpResponse<Array<BookingsBookingsFilteredDto>>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.BookingsSummaryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as BookingsStrictHttpResponse<Array<BookingsBookingsFilteredDto>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bookingsSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookingsSummary(params?: { body?: BookingsGetBookingsSummaryQuery }): Observable<Array<BookingsBookingsFilteredDto>> {
    return this.bookingsSummary$Response(params).pipe(
      map((r: BookingsStrictHttpResponse<Array<BookingsBookingsFilteredDto>>) => r.body as Array<BookingsBookingsFilteredDto>),
    );
  }

  /**
   * Path part for operation bookingsByUserId
   */
  static readonly BookingsByUserIdPath = '/api/Bookings/get/bookingsByUserId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookingsByUserId()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookingsByUserId$Response(params?: { body?: BookingsGetBookingsByUserIdQuery }): Observable<BookingsStrictHttpResponse<Array<BookingsBookingsFilteredDto>>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.BookingsByUserIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as BookingsStrictHttpResponse<Array<BookingsBookingsFilteredDto>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bookingsByUserId$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookingsByUserId(params?: { body?: BookingsGetBookingsByUserIdQuery }): Observable<Array<BookingsBookingsFilteredDto>> {
    return this.bookingsByUserId$Response(params).pipe(
      map((r: BookingsStrictHttpResponse<Array<BookingsBookingsFilteredDto>>) => r.body as Array<BookingsBookingsFilteredDto>),
    );
  }

  /**
   * Path part for operation postBookings
   */
  static readonly PostBookingsPath = '/api/Bookings/post/bookings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postBookings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postBookings$Response(params?: { body?: BookingsPostBookingsCommand }): Observable<BookingsStrictHttpResponse<boolean>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.PostBookingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as BookingsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postBookings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postBookings(params?: { body?: BookingsPostBookingsCommand }): Observable<boolean> {
    return this.postBookings$Response(params).pipe(map((r: BookingsStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation updateBooking
   */
  static readonly UpdateBookingPath = '/api/Bookings/patch/updateBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBooking()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBooking$Response(params?: { body?: BookingsPatchBookingCommand }): Observable<BookingsStrictHttpResponse<boolean>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.UpdateBookingPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as BookingsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateBooking$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBooking(params?: { body?: BookingsPatchBookingCommand }): Observable<boolean> {
    return this.updateBooking$Response(params).pipe(map((r: BookingsStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
