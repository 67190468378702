import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';

// Kendo
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { FilterModule } from '@progress/kendo-angular-filter';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { FormFieldModule, InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { DrawerModule, ExpansionPanelModule, LayoutModule, PanelBarModule, SplitterModule, TabStripModule } from '@progress/kendo-angular-layout';
import { ListBoxModule } from '@progress/kendo-angular-listbox';
import { MenusModule } from '@progress/kendo-angular-menu';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { PivotGridModule } from '@progress/kendo-angular-pivotgrid';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { PopoverModule, TooltipModule, TooltipsModule } from '@progress/kendo-angular-tooltip';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { UploadsModule } from '@progress/kendo-angular-upload';

// Components
import { DotsMenuButtonComponent } from './dots-menu-button/dots-menu-button.component';

// Types
import { FormlySelectModule } from '@ngx-formly/core/select';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { KendoFormlyArray } from './array/array.type';
import { KendoFormlyCheckbox } from './checkbox/checkbox.type';
import { KendoFormlyDate } from './date/date.type';
import { KendoFormlyDivider } from './divider/divider.type';
import { KendoFormlyFile } from './file/file-type';
import { KendoFormlyFormFieldWrapper } from './form-field/form-field.wrapper';
import { KendoFormlyGrid } from './grid/grid.component';
import { KendoFormlyInput } from './input/input.type';
import { KendoFormlyMultiSchema } from './multischema/multischema.type';
import { KendoFormlyObject } from './object/object.type';
import { KendoFormlyRadio } from './radio/radio.type';
import { KendoFormlySelect } from './select/select.type';
import { KendoFormlyTab } from './tab/tab.type';
import { KendoFormlyTextArea } from './textarea/textarea.type';
import { KendoFormlyImage } from './image/image.type';

export const KENDO_FORMLY_MODULE_EXPORT = [
  // General
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FormlySelectModule,
  ListBoxModule,
  ButtonsModule,
  ChartsModule,
  DateInputsModule,
  DialogsModule,
  DrawerModule,
  DropDownsModule,
  DropDownListModule,
  EditorModule,
  ExcelModule,
  ExpansionPanelModule,
  FilterModule,
  GridModule,
  IndicatorsModule,
  InputsModule,
  LabelModule,
  LayoutModule,
  MenusModule,
  PDFExportModule,
  PDFModule,
  PanelBarModule,
  SplitterModule,
  TabStripModule,
  ToolBarModule,
  TooltipModule,
  TreeListModule,
  UploadsModule,
  IconsModule,
  GaugesModule,
  ScrollViewModule,
  PivotGridModule,
  NotificationModule,
  TooltipsModule,
  PopoverModule,
  IntlModule,
  FormFieldModule,
  NavigationModule,
];

export const KENDO_FORMLY_COMPONENT_EXPORT = [
  DotsMenuButtonComponent,
  KendoFormlyCheckbox,
  KendoFormlyFormFieldWrapper,
  KendoFormlyInput,
  KendoFormlyRadio,
  KendoFormlySelect,
  KendoFormlyTextArea,
  KendoFormlyImage,
  KendoFormlyDivider,
  KendoFormlyTab,
  KendoFormlyDate,
  KendoFormlyFile,
  KendoFormlyGrid,
  KendoFormlyObject,
  KendoFormlyArray,
  KendoFormlyMultiSchema,
];

export function minItemsValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should NOT have fewer than ${field.props.minItems} items`;
}

export function maxItemsValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should NOT have more than ${field.props.maxItems} items`;
}

export function minLengthValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should NOT be shorter than ${field.props.minLength} characters`;
}

export function maxLengthValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should NOT be longer than ${field.props.maxLength} characters`;
}

export function minValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should be >= ${field.props.min}`;
}

export function maxValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should be <= ${field.props.max}`;
}

export function multipleOfValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should be multiple of ${field.props.step}`;
}

export function exclusiveMinimumValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should be > ${field.props.step}`;
}

export function exclusiveMaximumValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should be < ${field.props.step}`;
}

export function constValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should be equal to constant "${field.props.const}"`;
}

export function typeValidationMessage({ schemaType }: any) {
  return `should be "${schemaType[0]}".`;
}

@NgModule({
  imports: [
    ...KENDO_FORMLY_MODULE_EXPORT,
    FormlyModule.forRoot({
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'type', message: typeValidationMessage },
        { name: 'minLength', message: minLengthValidationMessage },
        { name: 'maxLength', message: maxLengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
        { name: 'multipleOf', message: multipleOfValidationMessage },
        { name: 'exclusiveMinimum', message: exclusiveMinimumValidationMessage },
        { name: 'exclusiveMaximum', message: exclusiveMaximumValidationMessage },
        { name: 'minItems', message: minItemsValidationMessage },
        { name: 'maxItems', message: maxItemsValidationMessage },
        { name: 'uniqueItems', message: 'should NOT have duplicate items' },
        { name: 'const', message: constValidationMessage },
        { name: 'enum', message: `must be equal to one of the allowed values` },
      ],
      types: [
        { name: 'checkbox', component: KendoFormlyCheckbox, wrappers: ['form-field'] },
        { name: 'input', component: KendoFormlyInput, wrappers: ['form-field'] },
        { name: 'string', extends: 'input' },
        { name: 'number', extends: 'input', defaultOptions: { props: { type: 'number' } } },
        { name: 'numberInput', extends: 'input', defaultOptions: { props: { type: 'number' } } },
        { name: 'integer', extends: 'input', defaultOptions: { props: { type: 'number' } } },
        { name: 'radio', component: KendoFormlyRadio, wrappers: ['form-field'] },
        { name: 'select', component: KendoFormlySelect, wrappers: ['form-field'] },
        { name: 'picklist', extends: 'select' },
        { name: 'enum', extends: 'select' },
        { name: 'boolean', extends: 'select' },
        { name: 'textarea', component: KendoFormlyTextArea, wrappers: ['form-field'] },
        { name: 'divider', component: KendoFormlyDivider, wrappers: ['form-field'] },
        { name: 'tab', component: KendoFormlyTab },
        { name: 'array', component: KendoFormlyArray },
        { name: 'object', component: KendoFormlyObject },
        { name: 'multischema', component: KendoFormlyMultiSchema },
        { name: 'file', component: KendoFormlyFile, wrappers: ['form-field'] },
        { name: 'date', component: KendoFormlyDate, wrappers: ['form-field'] },
        { name: 'grid', component: KendoFormlyGrid },
        { name: 'image', component: KendoFormlyImage, wrappers: ['form-field'] },
      ],
      wrappers: [{ name: 'form-field', component: KendoFormlyFormFieldWrapper }],
    }) as any,
  ],
  exports: [...KENDO_FORMLY_MODULE_EXPORT, ...KENDO_FORMLY_COMPONENT_EXPORT, FormlyModule],
  declarations: [...KENDO_FORMLY_COMPONENT_EXPORT],
})
export class KendoFormlyModule {}
