import moment from 'moment';

export abstract class CommonHelpers {
  /**
   * Converts pixels to centimeters.
   *
   * @param pixels - The number of pixels to convert.
   * @returns The equivalent value in centimeters.
   */
  public static pixelsToCentimeter(pixels: number): string {
    var n = 0;
    var cpi = 2.54; // centimeters per inch
    var dpi = 96; // dots per inch
    var ppd = window.devicePixelRatio; // pixels per dot
    return ((pixels * cpi) / (dpi * ppd)).toFixed(n);
  }

  /**
   * Generates a random integer between 1 and the specified maximum integer.
   *
   * @param maxInteger - The maximum integer value.
   * @returns A random integer between 1 and the maximum integer.
   */
  public static getRandomInteger(maxInteger: number): number {
    return Math.floor(Math.random() * (maxInteger - 1 + 1)) + 1; // The maximum is inclusive and the minimum is inclusive
  }

  /**
   * Returns the initials of a given full name.
   *
   * @param fullName - The full name to extract initials from.
   * @returns The initials of the full name.
   */
  public static getInitials(fullName: string): string {
    var names = fullName.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  /**
   * Converts a given date-time string to a local date object.
   *
   * @param dateTimeString - The date-time string to be converted.
   * @returns A Date object representing the local date and time.
   *
   * @remarks
   * If the input date-time string includes a 'Z' character, it is treated as a UTC date.
   * The function then converts it to the local date and time.
   * If the input date-time string does not include a 'Z' character, it is treated as a local date.
   */
  public static convertDateToLocalDate(dateTimeString: string): Date {
    const date = new Date(dateTimeString);
    if (!dateTimeString?.toString().includes('Z')) {
      return date;
    }
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const localDate = new Date(year, month, day, hours, minutes, seconds);
    return localDate;
  }

  /**
   * Formats a given date string according to the specified format.
   * If the year of the date is different from the current year, the year is appended to the format.
   *
   * @param date - The date string to format.
   * @param format - The format string to use for formatting the date.
   * @returns The formatted date string.
   */
  public static formatDate(date: string, format: string): string {
    const momentDate = moment(date);
    const currentYear = moment().year();
    const dateYear = momentDate?.year();

    if (dateYear !== currentYear) {
      if (!format?.toLocaleUpperCase()?.includes('YYYY') && !format?.toLocaleUpperCase()?.includes('YY')) {
        format = `${format}, YYYY`;
      }
    }

    return momentDate?.format(format);
  }
}
