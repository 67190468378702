import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { SharedApiBookingAvailabilitiesService, SharedApiBookingPoolVehicleAvailabilitesService } from '@verde/api';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../../services/verde-approval.service';
import { SidePanelWidth } from '../../../enums/sidepanel-width.enum';
import { UserService } from 'libs/core/src/lib/modules/authentication/services/user.service';
import { BookingsDataService } from 'libs/shared/src/lib/shared/services/booking/bookings-data.service';
import { DeviceTypeService } from 'libs/core/src/lib/services/device-type.service';

@Component({
  selector: 'verde-booking-availability-results',
  templateUrl: './booking-availability-results.component.html',
  styleUrls: ['./booking-availability-results.component.scss'],
})
export class BookingAvailabilityResultsComponent implements OnInit, OnChanges, OnDestroy {
  @Output() setSidePanelTitleText = new EventEmitter<string>();
  onDestroy$: Subject<any> = new Subject();

  get getOfficeId(): string {
    return this.bookingDataService?.bookingAvailabilitySearchDetails?.subOffice ?? this.bookingDataService?.bookingAvailabilitySearchDetails?.office;
  }
  get isInteractiveTemp(): boolean {
    const subOffice = this.bookingDataService?.bookingAvailabilitySearchDetails?.SubOfficeLocName;
    const office = this.bookingDataService?.bookingAvailabilitySearchDetails?.OfficeLocName;
    return (subOffice ?? office) === 'Waverley - Block A - Braintree' || (subOffice ?? office) === '29 Scott Street' ? true : false;
  }

  constructor(
    private spinner: NgxSpinnerService,
    public userService: UserService,
    private sidebarService: VerdeApprovalService,
    private bookingsDataService: BookingsDataService,
    private sharedApiBookingAvailabilitiesService: SharedApiBookingAvailabilitiesService,
    private sharedApiBookingPoolVehicleAvailabilitesService: SharedApiBookingPoolVehicleAvailabilitesService,
    public bookingDataService: BookingsDataService,
    public deviceTypeService: DeviceTypeService,
  ) {}

  ngOnInit(): void {
    this.sidebarService.getBookingAvailabilitySearchDetails().subscribe((data) => {
      this.bookingDataService.bookingAvailabilitySearchDetails = data;
      if (data) {
        if (this.isInteractiveTemp) {
          if (this.deviceTypeService.isMobile() || this.deviceTypeService.isTablet()) {
            this.sidebarService.setSidebarSize(SidePanelWidth.Full);
          } else {
            this.sidebarService.setSidebarSize(SidePanelWidth.TwoThirds);
          }
        }
      }
    });

    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.bookingDataService.disableAnimation = data;
    });

    this.open();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.openModal && changes.openModal.currentValue !== undefined) {
      this.open();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  open() {
    if (this.bookingDataService.bookingAvailabilitySearchDetails?.bookingTypeName.toLowerCase() === 'hot desk') {
      this.getBookingAvailability();
      this.bookingDataService.DisplayMessage =
        'Availabilities for ' +
        this.bookingDataService.bookingAvailabilitySearchDetails?.PrimaryLocName +
        ' at ' +
        this.bookingDataService.bookingAvailabilitySearchDetails?.OfficeLocName +
        ' at ' +
        this.bookingDataService.bookingAvailabilitySearchDetails?.SubOfficeLocName;
    } else if (this.bookingDataService.bookingAvailabilitySearchDetails?.bookingTypeName.toLowerCase() === 'pool vehicle') {
      this.getPoolVehicleBookingAvailability();
      this.bookingDataService.DisplayMessage = 'Availabilities for ' + this.bookingDataService.bookingAvailabilitySearchDetails?.PrimaryLocName;
    } else {
      this.bookingDataService.DisplayMessage = 'Availabilities';
    }

    this.setSidePanelTitleText.emit(this.bookingDataService.DisplayMessage);
  }

  private resetBookingAvailabilites(): void {
    this.bookingDataService.bookingAvailabilities = null;
  }

  getPoolVehicleBookingAvailability() {
    this.resetBookingAvailabilites();

    this.spinner.show('bookingAvailabilities');
    this.bookingDataService.bookingAvailabilitiesLoading = true;
    this.sharedApiBookingPoolVehicleAvailabilitesService
      .getPoolVehicleBookingAvailabilities({
        legalentityid: this.userService.user?.legalEntityId,
        bookingtypeid: this.bookingDataService.bookingAvailabilitySearchDetails?.bookingType,
        primarylocationid: this.bookingDataService.bookingAvailabilitySearchDetails?.primaryLocation,
        fromdate: moment(this.bookingDataService.bookingAvailabilitySearchDetails?.bookingFrom).format('YYYY-MM-DDThh:mm:ss[Z]'),
        todate: moment(this.bookingDataService.bookingAvailabilitySearchDetails?.bookingTo).format('YYYY-MM-DDThh:mm:ss[Z]'),
        departmentid: this.bookingDataService.bookingAvailabilitySearchDetails?.DepartmentToPass,
      })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        (allBookingAvailabilities) => {
          allBookingAvailabilities.sort((a, b) => {
            if (a.bt_bookingitem && b.bt_bookingitem) {
              const nameA = a.bt_bookingitem.toLowerCase();
              const nameB = b.bt_bookingitem.toLowerCase();
              if (nameA < nameB) {
                //sort string ascending
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            }

            return 0;
          });

          this.bookingDataService.bookingAvailabilityGridData = [...allBookingAvailabilities];
          this.bookingDataService.bookingsGridView = this.bookingDataService.bookingAvailabilityGridData;

          this.bookingDataService.bookingAvailabilities = {
            data: [...this.bookingDataService.bookingAvailabilityGridData],
            total: this.bookingDataService.bookingAvailabilityGridData.length,
          };
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('bookingAvailabilities');
          this.bookingDataService.bookingAvailabilitiesLoading = false;
        },
      );
  }

  getBookingAvailability() {
    this.resetBookingAvailabilites();

    this.spinner.show('bookingAvailabilities');
    this.bookingDataService.bookingAvailabilitiesLoading = true;

    this.sharedApiBookingAvailabilitiesService
      .getBookingAvailabilities({
        legalEntity: this.userService.user?.legalEntityId,
        bookingtypeid: this.bookingDataService.bookingAvailabilitySearchDetails?.bookingType,
        primarylocationid: this.bookingDataService.bookingAvailabilitySearchDetails?.primaryLocation,
        officeid: this.bookingDataService.bookingAvailabilitySearchDetails?.office,
        subofficeid: this.bookingDataService.bookingAvailabilitySearchDetails?.subOffice,
        fromdate: moment(this.bookingDataService.bookingAvailabilitySearchDetails?.bookingFrom).format('YYYY-MM-DDThh:mm:ss[Z]'),
        todate: moment(this.bookingDataService.bookingAvailabilitySearchDetails?.bookingTo).format('YYYY-MM-DDThh:mm:ss[Z]'),
      })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        (allBookingAvailabilities) => {
          allBookingAvailabilities.sort((a, b) => {
            if (a.bt_bookingitem && b.bt_bookingitem) {
              const nameA = a.bt_bookingitem.toLowerCase();
              const nameB = b.bt_bookingitem.toLowerCase();
              if (nameA < nameB) {
                //sort string ascending
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            }

            return 0;
          });

          this.bookingDataService.bookingAvailabilityGridData = [...allBookingAvailabilities];
          this.bookingDataService.bookingsGridView = this.bookingDataService.bookingAvailabilityGridData;

          this.bookingDataService.bookingAvailabilities = {
            data: [...this.bookingDataService.bookingAvailabilityGridData],
            total: this.bookingDataService.bookingAvailabilityGridData.length,
          };
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('bookingAvailabilities');
          this.bookingDataService.bookingAvailabilitiesLoading = false;
        },
      );
  }

  onBookingsFilter(inputValue: string): void {
    this.bookingDataService.bookingsGridView = process(this.bookingDataService.bookingAvailabilityGridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'bt_bookingitem',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'bt_VerdeBookings_BookingItem_bt_VerdeBook[0].bt_Employee.bt_fullname',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    this.bookingDataService.dataBinding.skip = 0;
  }

  cancel() {
    this.sidebarService.setShowSidebar(false);
  }

  refreshBookings() {
    this.bookingDataService.updateBookings(true);
    this.bookingsDataService.loadBookings();
    this.sidebarService.setShowSidebar(false);
  }
}
