<div class="main"
     style="padding: 0 20px; height: calc(100% - 68px)">
  <ngx-spinner [disableAnimation]="bookingDataService.disableAnimation"
               name="bookingAvailabilities"
               bdColor="rgb(255, 255, 255)"
               size="default"
               color="#6264a7"
               type="ball-clip-rotate"
               [fullScreen]="false">
    <p>Loading Data...</p>
  </ngx-spinner>

  <div class="scrollable-content"
       style="overflow-y: auto">
    <div style="margin-top: 20px">
      <div style="display: inline-flex; width: 100%; height: 100%">
        <div *ngIf="!isInteractiveTemp">
          <kendo-grid *ngIf="!bookingDataService.bookingAvailabilitiesLoading"
                      #grid
                      [kendoGridBinding]="bookingDataService.bookingsGridView"
                      [resizable]="true"
                      [sortable]="true"
                      [height]="360"
                      [sort]="bookingDataService.gridSort">
            <ng-template kendoGridToolbarTemplate>
              <input placeholder="Search all columns"
                     kendoTextBox
                     style="text-transform: none !important"
                     (input)="onBookingsFilter($event.target.value)" />
            </ng-template>
            <kendo-grid-column field="bt_bookingitem"
                               title="Booking Item"
                               [width]="120">
              <ng-template kendoGridCellTemplate
                           let-ListTable>
                {{ ListTable.bt_bookingitem }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Status"
                               field="bt_VerdeBookings_BookingItem_bt_VerdeBook"
                               [width]="350">
              <ng-template kendoGridCellTemplate
                           let-ListTable>
                <span *ngIf="bookingDataService.findBookingSlotBooking(ListTable)">
                  {{
                  bookingDataService.findBookingSlotBooking(ListTable)?.bt_Employee?.bt_fullname
                  }} from
                  {{
                  bookingDataService.findBookingSlotBooking(ListTable)?.bt_fromdate
                  | date : 'HH:mm' }}
                  to
                  {{
                  bookingDataService.findBookingSlotBooking(ListTable)?.bt_todate
                  | date : 'HH:mm' }} on {{
                  bookingDataService.findBookingSlotBooking(ListTable)?.bt_todate
                  | formatDate : 'MMM D' }}
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Actions"
                               field="bt_VerdeBookings_BookingItem_bt_VerdeBook"
                               [width]="100"
                               [sticky]="true">
              <ng-template kendoGridCellTemplate
                           let-ListTable>
                <span *ngIf="!bookingDataService.findBookingSlotBooking(ListTable)">
                  <button kendoButton
                          themeColor="primary"
                          (click)="bookingDataService.openConfirmModel(ListTable)">Book</button>
                </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>

        <div style="margin-left: 10px"
             *ngIf="!bookingDataService.bookingAvailabilitiesLoading && bookingDataService.bookingAvailabilitySearchDetails?.bookingTypeName === 'Hot Desk'">
          <img *ngIf="!isInteractiveTemp"
               width="520"
               height="360"
               [src]="bookingDataService.bookingAvailabilitySearchDetails?.SelectedSubOffice" />
          <verde-client *ngIf="isInteractiveTemp"
                        [clientId]="userService?.user?.legalEntityId"
                        [officeId]="getOfficeId"></verde-client>
        </div>
      </div>
    </div>
  </div>
  <div class="form-buttons"
       style="display: flex; justify-content: space-between; padding: 10px; background: white; position: absolute; bottom: 0; left: 0; right: 0">
    <button kendoButton
            style="background-color: lightgrey; color: black"
            (click)="cancel()">Cancel</button>
  </div>
</div>

<verde-modal id="bookingDetailsModal"
             class="booking-details-modal"
             [modalTitle]="'Booking Details'"
             submitButton="Book"
             [hasCancelButton]="true"
             (actionButton)="bookingDataService.bookItem()">
  <div style="position: relative">
    <ngx-spinner [disableAnimation]="bookingDataService.disableAnimation"
                 name="bookingdetailsloading"
                 bdColor="rgb(237, 237, 237)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">
    </ngx-spinner>

    <p>
      Confirm Booking: Would you like to book {{ bookingDataService.bookingAvailabilitySearchDetails?.BookingItemName }}
      on {{
      bookingDataService.bookingDate }} from
      {{ bookingDataService.bookingStartDate }} to {{ bookingDataService.bookingEndDate }}?
    </p>
  </div>
</verde-modal>