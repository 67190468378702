/* tslint:disable */
/* eslint-disable */
import { CommonColumnData } from './common-column-data';
import { CommonSortData } from './common-sort-data';
import { CommonWebDynamicFilterDto } from './common-web-dynamic-filter-dto';
export interface CommonGetDynamicFormLookupCommand {
  columns?: null | Array<CommonColumnData>;
  dependency?: CommonWebDynamicFilterDto;
  id?: null | string;
  label?: null | string;
  sort?: null | Array<CommonSortData>;
  value?: null | string;
}
