import { Injectable, ViewChild } from '@angular/core';
import { GridDataResult, DataBindingDirective } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  BookingAvailabilitiesFiltered,
  BookingAvailability,
  BookingsApiBookingsService,
  BookingsBookingsFilteredDto,
  BookingsGetBookingsByUserIdQuery,
  BookingsPatchBookingCommand,
  BookingsPostBookingsCommand,
} from '@verde/api';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../../layout/verde-approval-panel/services/verde-approval.service';
import { ModalService } from '../../modals/modal.service';
import { CommonHelpers, UserService } from '@verde/core';

@Injectable({
  providedIn: 'root',
})
export class BookingsDataService {
  refreshBooking: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  allBookings: ReplaySubject<BookingsBookingsFilteredDto[]> = new ReplaySubject<BookingsBookingsFilteredDto[]>(1);
  onDestroy$: Subject<any> = new Subject();

  bookingStartDate: string;
  bookingEndDate: string;
  bookingDate: string;
  bookingAvailabilitySearchDetails: any;
  BookingItemName: string;
  bookingItemSelected: BookingAvailabilitiesFiltered;

  bookingAvailabilities: GridDataResult;
  bookingAvailabilityGridData: BookingAvailabilitiesFiltered[] = [];

  bookingId: string;
  showConfirmationModal: boolean;
  bookingAvailabilitiesLoading: boolean;

  DisplayMessage: string;
  disableAnimation: boolean;

  deskBookedImageStyle = 'filter: invert(50%) sepia(100%) saturate(5000%) hue-rotate(330deg) brightness(90%);';
  deskUnavailableImageStyle = 'filter: grayscale(100%) brightness(90%);';
  deskAvailableCursorStyle = 'cursor: pointer;';
  deskUnavailableCursorStyle = 'cursor: default;';

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  bookingsGridView: any[];

  gridSort: SortDescriptor[] = [
    {
      field: 'bt_bookingitem',
      dir: 'asc',
    },
  ];

  constructor(
    private bookingService: BookingsApiBookingsService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private sidebarService: VerdeApprovalService,
    private modalService: ModalService,
  ) {}

  loadBookings(): void {
    debugger;
    this.bookingService
      .bookingsByUserId({
        body: {
          userId: this.userService.user?.employeeId,
          legalEntityID: this.userService.user?.legalEntityId,
        },
      })
      .pipe(take(1))
      .subscribe({
        next: (o) => this.allBookings.next(o),
      });
  }

  openConfirmModel(bookingItem: BookingAvailabilitiesFiltered) {
    this.bookingItemSelected = bookingItem;
    this.bookingStartDate = moment(this.bookingAvailabilitySearchDetails?.bookingFrom).format('HH:mm');
    this.bookingEndDate = moment(this.bookingAvailabilitySearchDetails?.bookingTo).format('HH:mm');
    this.bookingDate = CommonHelpers.formatDate(this.bookingAvailabilitySearchDetails?.bookingFrom, 'MMM D');
    this.bookingAvailabilitySearchDetails.bookingItem = this.bookingItemSelected.bt_verdebookingitemsid;
    this.bookingAvailabilitySearchDetails.BookingItemName = this.bookingItemSelected.bt_bookingitem;
    this.modalService.open(`bookingDetailsModal`);
  }

  bookItem(legalEntity: string = this.userService.user?.legalEntityId, employee: string = this.userService.user?.employeeId) {
    this.spinner.show('bookingdetailsloading');

    const booking: BookingsBookingsFilteredDto = {
      bt_fromdate: moment(this.bookingAvailabilitySearchDetails?.bookingFrom).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      bt_todate: moment(this.bookingAvailabilitySearchDetails?.bookingTo).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      odataPostLegalEntity: '/bthr_legalentities(' + legalEntity + ')',
      odataPostDuration: '/bt_verdebookingduration(' + this.bookingAvailabilitySearchDetails?.bookingDuration + ')',
      odataPostPrimaryLocation: '/bthr_location(' + this.bookingAvailabilitySearchDetails?.primaryLocation + ')',
      odataPostBookingType: '/bthr_location(' + this.bookingAvailabilitySearchDetails?.bookingType + ')',
      odataPostBookingItem: '/bt_verdebookingitems(' + this.bookingAvailabilitySearchDetails?.bookingItem + ')',
      odataPostEmployee: '/bthr_employee(' + employee + ')',
      odataPostOffice: this.bookingAvailabilitySearchDetails?.office != null ? '/bt_verdeoffice(' + this.bookingAvailabilitySearchDetails?.office + ')' : null,
      odataPostSubOffice:
        this.bookingAvailabilitySearchDetails?.subOffice != null ? '/bt_verdesuboffice(' + this.bookingAvailabilitySearchDetails?.subOffice + ')' : null,
      odataPostDepartment: '/bthr_department(' + this.bookingAvailabilitySearchDetails?.DepartmentToPass + ')',
      bt_bookingreason: this.bookingAvailabilitySearchDetails?.BookingReason != null ? this.bookingAvailabilitySearchDetails?.BookingReason : null,
      bt_Departmental: false,
      stateCode: 0, //Active
    };

    const newBooking: BookingsPostBookingsCommand = booking;

    this.bookingService
      .postBookings({ body: newBooking })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        (success) => {
          console.log(success);
          this.updateBookings(true);
          this.loadBookings();
          this.sidebarService.setShowSidebar(false);
          this.spinner.hide('bookingdetailsloading');
        },
        (error) => {
          console.error(error);
        },
      );
  }

  bookDesk(deskId: string) {
    const bookingItem = this.bookingsGridView.find((desk) => desk?.bt_bookingitem === `${deskId}`);
    if (bookingItem && this.findBookingSlotBooking(bookingItem) == null) {
      this.openConfirmModel(bookingItem);
    }
  }
  getDeskImageStyle(deskId: string) {
    const bookingItem = this.bookingsGridView.find((desk) => desk?.bt_bookingitem === `${deskId}`);
    if (bookingItem) {
      const bookedSlot = this.findBookingSlotBooking(bookingItem);
      return bookedSlot != null ? this.deskBookedImageStyle : '';
    } else {
      return this.deskUnavailableImageStyle;
    }
  }
  getDeskCursorStyle(deskId: string) {
    const bookingItem = this.bookingsGridView.find((desk) => desk?.bt_bookingitem === `${deskId}`);
    if (bookingItem) {
      const bookedSlot = this.findBookingSlotBooking(bookingItem);
      return bookedSlot != null ? this.deskUnavailableCursorStyle : this.deskAvailableCursorStyle;
    } else {
      return this.deskUnavailableCursorStyle;
    }
  }
  getDeskToolTip(deskId: string) {
    const bookingItem = this.bookingsGridView.find((desk) => desk?.bt_bookingitem === `${deskId}`);
    if (bookingItem) {
      const bookedSlot = this.findBookingSlotBooking(bookingItem);
      return bookedSlot != null
        ? `${bookingItem?.bt_bookingitem} Booked by ${bookedSlot?.bt_Employee?.bt_fullname} from ${moment(bookedSlot?.bt_fromdate).format('HH:mm')} to ${moment(
            bookedSlot?.bt_todate,
          ).format('HH:mm')} on ${CommonHelpers.formatDate(this.bookingAvailabilitySearchDetails?.bookingFrom, 'MMM D')}`
        : `Book ${bookingItem?.bt_bookingitem} from ${moment(this.bookingAvailabilitySearchDetails?.bookingFrom).format('HH:mm')} to ${moment(
            this.bookingAvailabilitySearchDetails?.bookingTo,
          ).format('HH:mm')} on ${CommonHelpers.formatDate(this.bookingAvailabilitySearchDetails?.bookingFrom, 'MMM D')}`;
    } else {
      return `${bookingItem?.bt_bookingitem ?? deskId} Unavailable`;
    }
  }

  findBookingSlotBooking(bookingItem: any): BookingAvailability | null {
    // Convert slot dates to Date objects
    const bookings: BookingAvailability[] = bookingItem?.bt_VerdeBookings_BookingItem_bt_VerdeBook ?? [];

    const slotStartDate = new Date(this.bookingAvailabilitySearchDetails?.bookingFrom);
    const slotEndDate = new Date(this.bookingAvailabilitySearchDetails?.bookingTo);

    // Find the booking that overlaps with the slot
    for (const booking of bookings) {
      const bookingStartDate = CommonHelpers.convertDateToLocalDate(booking.bt_fromdate);
      const bookingEndDate = CommonHelpers.convertDateToLocalDate(booking.bt_todate);

      if (slotStartDate < bookingEndDate && bookingStartDate < slotEndDate) {
        const bookingTemp = {
          bt_fromdate: bookingStartDate?.toISOString(),
          bt_todate: bookingEndDate?.toISOString(),
          bt_Employee: booking.bt_Employee,
          bt_bookingno: booking.bt_bookingno,
          odataEmployee: booking.odataEmployee,
          statecode: booking.statecode,
        } as BookingAvailability;
        return bookingTemp;
      }
    }

    return null;
  }

  postBooking(booking: BookingsPostBookingsCommand): Observable<boolean> {
    return this.bookingService.postBookings({ body: booking });
  }

  updateBooking(booking: BookingsPatchBookingCommand): Observable<boolean> {
    return this.bookingService.updateBooking({ body: booking });
  }

  getBookingSummary(userRequest: BookingsGetBookingsByUserIdQuery): Observable<BookingsBookingsFilteredDto[]> {
    return this.bookingService.bookingsSummary({ body: userRequest });
  }

  getBookingByUserId(userRequest: BookingsGetBookingsByUserIdQuery): Observable<BookingsBookingsFilteredDto[]> {
    return this.bookingService.bookingsByUserId({ body: userRequest });
  }

  updateBookings(bookings: boolean) {
    this.refreshBooking.next(bookings);
  }
}
