/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { Annotation } from '../models/annotation';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class SharedApiAnnotationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAnnotationsByObjectId
   */
  static readonly GetAnnotationsByObjectIdPath = '/api/Annotation/annotationsbyobjectid/{objectID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAnnotationsByObjectId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnnotationsByObjectId$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    objectID: string;
  }): Observable<StrictHttpResponse<Array<Annotation>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiAnnotationService.GetAnnotationsByObjectIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('objectID', params.objectID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Annotation>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAnnotationsByObjectId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnnotationsByObjectId(params: { subscriptionKey?: string; APIURL?: string; objectID: string }): Observable<Array<Annotation>> {
    return this.getAnnotationsByObjectId$Response(params).pipe(map((r: StrictHttpResponse<Array<Annotation>>) => r.body as Array<Annotation>));
  }

  /**
   * Path part for operation postAnnotation
   */
  static readonly PostAnnotationPath = '/api/Annotation/post/annotation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAnnotation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postAnnotation$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: Annotation }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiAnnotationService.PostAnnotationPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAnnotation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postAnnotation(params?: { subscriptionKey?: string; APIURL?: string; body?: Annotation }): Observable<string> {
    return this.postAnnotation$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }
}
