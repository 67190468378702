import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { UserService } from '@verde/core';
import { ModalService, WebDynamicFormType, WebDynamicService } from '@verde/shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'verde-web-dynamic-form',
  templateUrl: './web-dynamic-form.component.html',
  styleUrls: ['./web-dynamic-form.component.scss'],
})
export class WebDynamicFormComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  form: FormGroup;
  model: any;
  additional: any;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];
  disableAnimation: boolean;
  notificationMessage = '';
  formType: WebDynamicFormType;
  WebDynamicFormType: typeof WebDynamicFormType = WebDynamicFormType;

  constructor(
    public userService: UserService,
    private webDynamicService: WebDynamicService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
  ) {
    this.spinner?.show('dynamicFormSpinner');
    this.webDynamicService.webDynamicFormModel$.pipe(take(1)).subscribe((webDynamicFormModel) => {
      this.spinner?.hide('dynamicFormSpinner');

      if (webDynamicFormModel) {
        this.form = webDynamicFormModel.form;
        this.options = {};
        this.fields = webDynamicFormModel.fields;
        this.formType = webDynamicFormModel.formType;
        this.additional = webDynamicFormModel.additional;

        setTimeout(() => {
          this.model = webDynamicFormModel?.args?.model ?? {};
          this.form.patchValue(this.model);
        });
      } else {
        this.throwError();
      }
    });
  }

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((disableAnimation) => {
      this.disableAnimation = disableAnimation;
    });
  }

  openSubmitModal() {
    //
  }

  submit() {
    //
  }

  throwError(errorMessage?: string) {
    this.notificationMessage = errorMessage ?? 'Please try again, and if the issue persists, please log a support request';
    this.modalService?.open('errorModal');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
