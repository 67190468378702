/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-console */
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { UserApiConfigService, UserApiUserService, UserAppConfigDto, UserVerdeErrorType } from '@verde/api';
import { AuthenticationService, DeviceTypeService, NavigationService, SideMenuDrawerItem, StorageService, UserService } from '@verde/core';
import { DotsMenuItem, ModalService, VerdeApprovalService } from '@verde/shared';
import { ThemeService } from '@verde/theme';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DashboardService } from 'apps/verde/src/app/features/dashboard/services/dashboard.service';

@Component({
  selector: 'verde-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  private onDestroy$ = new Subject<boolean>();

  config: UserAppConfigDto = {};

  noIDMatchError = false;
  disableAnimation: boolean;
  scrollNum = 0;
  showFAB = false;
  showOnlineTrainingModal = false;
  showSocialMediaLoader = false;
  modalButtonText = 'Next';
  loadingImageURL: string | null = null;
  loadingImageURLMobile: string | null = null;
  showNextButton = true;
  dotMenuItems: DotsMenuItem[] = [];
  showRefreshIcon = true;
  sidebarSize: number;
  enableSplashScreen = false;
  splashScreenURL = '';
  hasContinued = false;
  allowContinue = false;

  constructor(
    private userApiUserService: UserApiUserService,
    private storageService: StorageService,
    private spinner: NgxSpinnerService,
    public authenticationService: AuthenticationService,
    public userService: UserService,
    public navigationService: NavigationService,
    public deviceTypeService: DeviceTypeService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private router: Router,
    private sidebarService: VerdeApprovalService,
    private userApiConfigService: UserApiConfigService,
    private cdr: ChangeDetectorRef,
    public myWorkspaceService: DashboardService,
    @Inject('environment') public environment: any,
  ) {
    const self = this;

    combineLatest([self.userService.loaded$, self.userService.config$])
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(self.onDestroy$))
      .subscribe(async (data) => {
        self.config = data[1];

        if (data[0] && self.config !== undefined) {
          self.spinner.hide('apploadingbutton');

          self.splashScreenURL = self.config.verdeEnvironmentConfig?.splashScreenURL;
          self.enableSplashScreen = self.config.verdeEnvironmentConfig?.enableSplashScreen;

          if (self.config.verdeEnvironmentConfig?.verdeError) {
            if (self.config.verdeEnvironmentConfig?.verdeErrorType === UserVerdeErrorType.EmployeeFirstTimeLogin) {
              self.modalService.close('appLoadingModal');
              self.modalService.open('validateUserIDModal');
            } else {
              self.router.navigate(['/login']);
            }
          } else {
            self.allowContinue = true;
            this.closeInitialModal();
          }
        } else {
          self.spinner.show('apploadingbutton');
        }
      });

    self.determineLoadingImage();

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/dashboard') {
          self.showRefreshIcon = true;
        } else {
          self.showRefreshIcon = false;
        }
      }
    });
  }

  contextIsBrowser(): boolean {
    return !this.authenticationService.authState$.getValue().isTeamsAuthed;
  }

  dataverseEmbedActive(): boolean {
    return window.location.pathname.toString() === '/dataverse-embed';
  }

  fleetActive(): boolean {
    return window.location.pathname.toString().includes('/fleet');
  }

  async ngOnInit() {
    this.themeService.apply();

    this.navigationService.determineSelected();

    window.addEventListener(
      'scroll',
      () => {
        this.scrollNum = window.scrollY;

        if (this.scrollNum >= 100) {
          this.showFAB = true;
        } else {
          this.showFAB = false;
        }
      },
      false,
    );

    this.storageService.sessionDeleteKey('initialLoadDone');

    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      this.config = config;
    });

    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });

    this.sidebarService
      .getSidebarSize()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((size) => {
        this.sidebarSize = size;
      });
  }

  ngAfterViewInit() {
    this.checkDeviceLogic();
    this.spinner.show('apploadingbutton');
  }

  // #region Three Dot Menu Logic
  menuClicked() {
    this.dotMenuItems = [];
    const tempItems: DotsMenuItem[] = [];
    const legalEntityConfig = this.userService.legalEntityConfig;

    if (legalEntityConfig.communication) {
      tempItems.push({ id: 'bt_communication', text: 'Communication' });
    }

    if (legalEntityConfig.learning) {
      tempItems.push({ id: 'bt_learn', text: 'Learn' });
    }

    if (legalEntityConfig.support) {
      tempItems.push({ id: 'bt_helpdesk', text: 'Help Desk' });
    }

    tempItems.push({ id: 'worldnews', text: 'World News' });

    //if (this.config?.legalEntityConfig?.reportIssue) {
    tempItems.push({ id: 'bt_verdeissue', text: 'Issues' });
    //}

    tempItems.push({ id: 'bt_info', text: 'Info' });

    tempItems.push({ id: 'bt_opportunities', text: 'Opportunities' });

    tempItems.push({ id: 'my_calendar', text: 'My Calendar' });

    tempItems.push({ id: 'graph_test', text: 'Graph Test' });

    if (tempItems.length === 0) {
      tempItems.push({ text: 'No Options' });
    }

    this.dotMenuItems = [...tempItems];
  }

  menuItemClicked(menuItem: DotsMenuItem) {
    if (menuItem.id === 'bt_communication') {
      this.navigateSomewhere(menuItem.id, true);
    } else if (menuItem.id === 'bt_learn') {
      this.navigateSomewhere(menuItem.id, true);
    } else if (menuItem.id === 'bt_helpdesk') {
      this.navigateSomewhere(menuItem.id, true);
    } else if (menuItem.id === 'bt_info') {
      this.navigateSomewhere(menuItem.id, true);
    } else if (menuItem.id === 'bt_verdeissue') {
      this.navigateSomewhere(menuItem.id, true);
    } else if (menuItem.id === 'bt_opportunities') {
      this.navigateSomewhere(menuItem.id, true);
    } else if (menuItem.id === 'my_calendar') {
      this.navigateSomewhere(menuItem.id, true);
    } else if (menuItem.id === 'graph_test') {
      this.sidebarService.setSidebarSize(65);
      this.sidebarService.setSidebarType('verde-graph-test');
      this.sidebarService.setShowSidebar(true);
    }
  }

  // #endregion Three Dot Menu Logic

  navigateSomewhere(where: string, isMenuItem: boolean) {
    let selectedItem: SideMenuDrawerItem;

    if (isMenuItem) {
      this.navigationService.items.forEach((t) => {
        if (t.selector === where) {
          selectedItem = t;
        }
      });

      this.navigationService.onSelect(selectedItem?.drawerItem);

      // if (where === 'bt_myprofile') {
      //   if (!this.generalDisabled) {
      //     this.profileNavService.changeShowHomeTab(true);
      //   } else {
      //     this.profileNavService.changeBlockNavigation({ type: 'internal', flag: true, where: 'General' });
      //   }
      // }
    } else {
      this.router.navigate([where]);
    }
  }

   refreshMyWorkspace() {
     this.myWorkspaceService.setRefreshMyWorkspace(true);
   }

  checkDeviceLogic() {
    if ((window.location.pathname.toString() === '/' || window.location.pathname.toString() === '/dashboard') && !this.deviceTypeService.isMobile()) {
      // if (environment.production) {
      const now = moment();
      const morningEnd = moment('09:59:59', 'hh:mm:ss');

      if (now.isAfter(morningEnd)) {
        this.modalButtonText = 'Continue';
        this.cdr.detectChanges();
      }
      this.modalService.open('appLoadingModal');
      // }
    }
  }

  determineLoadingImage() {
    const max = 12;
    const randomNum: number = Math.floor(Math.random() * (max - 1 + 1)) + 1;

    this.loadingImageURL = 'https://engageimages.z1.web.core.windows.net/Images/Verde/Splash/images/verdesplash' + randomNum + '.jpg';
    this.loadingImageURLMobile = 'https://engageimages.z1.web.core.windows.net/Images/Verde/Splash/images/Mobile/verdesplash' + randomNum + '.jpg';
  }

  forceAppReload() {
    location.reload();
  }

  navDataverseEmbedding() {
    this.router.navigate(['/dataverse-embed']);
  }

  // Scroll button
  scrollMain(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  async checkIDValid(myForm: FormGroup) {
    const employee = this.userService.user;

    if (myForm.value.validateIDNo === employee.idNumber) {
      await this.userApiUserService
        .updateUser({
          body: {
            user: {
              ...this.userService.user,
              employeeId: employee.employeeId,
              azureObjectId: this.authenticationService.authState$.getValue().azureObjectId,
              tenantId: this.authenticationService.authState$.getValue().tenantId,
            },
          },
        })
        .toPromise();

      this.allowContinue = true;
      this.closeInitialModal();
    } else {
      this.noIDMatchError = true;
      this.modalService.open('validateUserIDModal');
    }
  }

  hideOnlinetrainingModal() {
    this.showOnlineTrainingModal = false;
  }

  continueInitialModal() {
    const now = moment();
    const morningEnd = moment('09:59:59', 'hh:mm:ss');

    if (now.isAfter(morningEnd)) {
      this.modalService.close('appLoadingModal');
    } else {
      if (this.modalButtonText === 'Next') {
        if (this.enableSplashScreen && (window.location.pathname.toString() === '/' || window.location.pathname.toString() === '/dashboard')) {
          this.spinner.show('apploadingbutton');
          this.showNextButton = false;
          this.hasContinued = true;

          this.showSocialMediaLoader = true;

          setTimeout(() => {
            this.spinner.hide('apploadingbutton');
            this.showSocialMediaLoader = false;
          }, 1500);
        } else {
          this.modalService.close('appLoadingModal');
        }
      } else {
        this.modalService.close('appLoadingModal');
      }
    }
  }

  closeInitialModal() {
    this.modalService.close('appLoadingModal');
  }

  async microsoftLogout() {
    await this.userApiConfigService
      .removeSecuritySession({
        body: {
          employeeId: this.config?.user?.employeeId,
          userEmail: this.config?.user?.email,
          legalEntityId: this.config?.user?.legalEntityId,
        },
      })
      .toPromise();

    this.authenticationService.signOut();
    this.storageService.localClearAll();
    this.storageService.sessionDeleteKey('user');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
