<!-- side bar main wrapper -->
<div class="side-bar" [ngClass]="{ 'side-bar-collapsed': !(showSidebar | async) }">
  <!-- overlay -->
  <div
    class="side-bar-overlay"
    [ngStyle]="{ transition: 'background-color ' + duration + 's, visibility ' + duration + 's' }"
    [ngClass]="{ 'side-bar-overlay-collapsed': !(showSidebar | async) }"
  ></div>

  <!-- side bar-->
  <div class="side-bar-menu-container" [ngStyle]="getSidebarStyle(showSidebar | async)">
    <!-- close button -->
    <div class="side-bar-header">
      <p class="side-bar-title" *ngIf="sidebarType === 'view-document'">
        {{ documentName }}
      </p>
      <p class="side-bar-title" *ngIf="sidebarType === 'dummy-sidepanel'">Dummy Sidepanel</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'structure-activity'">Structure Activity</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'view-user-access-grid'">User Access</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'approval'">Approval</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'funds'">Funds</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'measureView'">Measure View</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'measureEdit'">Measure Edit</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'measureAdd'">Add Measure</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'groupAdd'">Add Group</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'groupView'">View Group</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'groupEdit'">Edit Group</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'subgroupAdd'">Add Subgroup</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'subgroupView'">View Subgroup</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'subgroupEdit'">Edit Subgroup</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'templateView'">
        {{ selectedTemplateName }}
      </p>
      <p class="side-bar-title" *ngIf="sidebarType === 'templateEdit'">Edit Template</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'benchmarkAdd'">Add Benchmark</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'personalPerformanceReview'">Performance Review</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'peerReview'">Peer Review</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'fleet-request-item'">Request New Fleet Item</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'payroll'">Payroll Import</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'fleet-return-item'">Return Fleet Item</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'fleet-entity-transfer'">Entity Transfer - {{ selectedFleetItem.bthr_licensenumber }}</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'fleet-odometer-update'">Odometer Update</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'qualifications'">{{ qualificationTitle }}</p>
      <p *ngIf="sidebarType === 'verdeDynamicForm'" class="side-bar-title">{{ universalTitleVar }}</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'employeeExit'">Employee Exit</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'structureChangeRequest'">Structure Change Request</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'structureDisposeRequest'">Structure Dispose Request</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'positionChangeRequest'">Position Change Request</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'employeeExitEdit'">Employee Exit Edit</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'newopportunity'">New Opportunity</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'editopportunity'">Update Opportunity</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'dynamic-form'">Dynamic Form</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'bulkUpload'">Bulk Upload</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'bulkUploadAdmin'">Create new Document Type</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'viewtaskcomponent'">View Task</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'employeeResignation'">
        {{ resignationTitle.fullNameWithEmpId }} - {{ resignationTitle.legalEntityName }}
      </p>
      <p class="side-bar-title" *ngIf="sidebarType === 'employeeResignationEdit'">
        {{ resignationEdit.bt_fullname }} - {{ resignationEdit.bt_legalentity.bthr_legalentityname }}
      </p>
      <p class="side-bar-title" *ngIf="sidebarType === 'employeeResignationView'">
        {{ resignationTitle.fullNameWithEmpId }} - {{ resignationTitle.legalEntityName }}
      </p>
      <p class="side-bar-title" *ngIf="sidebarType === 'employeeOnboarding'">Employee Onboarding</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'employeeOnboardingEdit'">Employee Onboarding Edit</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'employeeOnboardingAdditional'">Employee Onboarding Menu</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'successor-details' && successorDetails.type != 'create'">
        Successor Details -
        {{ successorDetails.successor.bt_successorfullname }}
      </p>
      <p class="side-bar-title" *ngIf="sidebarType === 'successor-details' && successorDetails.type == 'create'">New Successor</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'bookingAvailabilitySearch'">Booking Availability Search</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'bookingAvailabilityResults'">{{ bookingAvailabilityResultsTitle }}</p>
      <p class="side-bar-title" *ngIf="sidebarType === 'verde-graph-test'">Graph Api Test</p>
      <span class="material-icons side-bar-close" (click)="onSidebarClose()"> clear </span>
    </div>

    <!-- side bar content -->
    <div class="side-bar-content-container">
      <!-- Approval -->
      <div *ngIf="sidebarType === 'approval'">
        <div class="grid-container">
          <!--col 1-->
          <div *ngIf="approvalLogs?.length > 0" class="my-stepper">
            <kendo-stepper
              *ngIf="showStepper"
              #stepper
              [steps]="steps"
              stepType="full"
              [(currentStep)]="current"
              [linear]="false"
              (activate)="activate($event)"
              (currentStepChange)="currentStepChange($event)"
              orientation="vertical"
            >
              <ng-template kendoStepperLabelTemplate let-step>
                <div stlye="display: block">
                  <label *ngIf="step.status != 'Submitted'" style="min-width: 180px">{{ step.label }}</label>
                  <label *ngIf="step.status === 'Submitted'" style="min-width: 180px; color: #54559c; font-weight: bold">{{ step.label }}</label>
                  <br />
                  <label *ngIf="step.status != 'Submitted'" style="min-width: 180px">{{ step.info }}</label>
                  <label *ngIf="step.status === 'Submitted'" style="min-width: 180px; color: #54559c; font-weight: bold">{{ step.info }}</label>
                  <br />
                  <label *ngIf="step.status != 'Submitted'" style="min-width: 180px">{{ step.status }}</label>
                  <label *ngIf="step.status === 'Submitted'" style="min-width: 180px; color: #54559c; font-weight: bold">{{ step.status }}</label>
                  <!--<img class="info-img" src="https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/info.svg" title="{{step.info}}">-->

                  <kendo-svg-icon *ngIf="step.status === 'Approved'" [icon]="icons.checkIcon" size="medium" style="color: forestgreen"></kendo-svg-icon>

                  <kendo-svg-icon
                    *ngIf="step.status === 'Submitted' || step.status === 'New'"
                    [icon]="icons.chevronDoubleRightIcon"
                    size="medium"
                    style="color: #eac736"
                  ></kendo-svg-icon>

                  <kendo-svg-icon *ngIf="step.status === 'Rejected'" [icon]="icons.xIcon" size="medium" style="color: darkred"></kendo-svg-icon>
                </div>
              </ng-template>
            </kendo-stepper>
          </div>
          <!--col 2-->
          <div class="description-area">
            <div class="text-area grid-container-textbox">
              <div>
                <label>Status:</label>
                <br />
                <br />
                <label>Type:</label>
                <br />
                <br />
                <label>Info:</label>
              </div>
              <div>
                <kendo-textbox [readonly]="true" [value]="selectedStep?.status"></kendo-textbox>
                <br />
                <br />
                <kendo-textbox [readonly]="true" [value]="selectedStep?.info"></kendo-textbox>
                <br />
                <br />
                <kendo-textbox [readonly]="true" [value]="selectedStep?.label"></kendo-textbox>
              </div>
            </div>

            <!--Approvers Grid-->
            <div>
              <kendo-grid style="max-width: 400px" id="approverLogs" [scrollable]="true" [kendoGridBinding]="approversGridData">
                <kendo-grid-column field="bt_Employee.bt_fullname" title="Approvers" [width]="150"> </kendo-grid-column>

                <kendo-grid-column field="bt_approvalstatus" title="" [width]="50">
                  <ng-template kendoGridCellTemplate let-ListTable>
                    <span>
                      <kendo-svg-icon
                        *ngIf="ListTable.bt_approvalstatus == 692360003"
                        [icon]="icons.checkIcon"
                        size="small"
                        style="color: forestgreen"
                      ></kendo-svg-icon>
                      <kendo-svg-icon
                        *ngIf="ListTable.bt_approvalstatus == 692360002"
                        [icon]="icons.xIcon"
                        size="small"
                        style="color: darkred"
                      ></kendo-svg-icon>
                    </span>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="bt_approvaldate" title="Date" [width]="150">
                  <ng-template kendoGridCellTemplate let-ListTable>
                    {{ ListTable.bt_approvaldate | date : 'yyyy/MM/dd HH:mm a' : 'UTC' }}
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="bt_comment" title="Comment" [width]="200"> </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>

      <!-- Dummy Sidepanel -->
      <div *ngIf="sidebarType === 'dummy-sidepanel'">
        <verde-a-dummy-sidepanel></verde-a-dummy-sidepanel>
      </div>

      <!-- View Document -->
      <div *ngIf="sidebarType === 'view-document'" id="pdf-div" class="pdf-view"></div>

      <!-- View user acess -->
      <div *ngIf="sidebarType === 'view-user-access-grid'">
        <verde-user-access-grid></verde-user-access-grid>
      </div>

      <!-- View Structure Activity -->
      <div *ngIf="sidebarType === 'structure-activity'">
        <verde-structure-activity></verde-structure-activity>
      </div>

      <!-- Employee Funds -->
      <div *ngIf="sidebarType === 'funds'">
        <verde-employee-funds></verde-employee-funds>
      </div>

      <!-- Template Measure View -->
      <div *ngIf="sidebarType === 'measureView'">
        <verde-template-measure-view></verde-template-measure-view>
      </div>

      <!-- Template Measure Edit -->
      <div *ngIf="sidebarType === 'measureEdit'">
        <verde-template-measure-edit></verde-template-measure-edit>
      </div>

      <!-- Template Measure Add -->
      <div *ngIf="sidebarType === 'measureAdd'">
        <verde-template-measure-add></verde-template-measure-add>
      </div>

      <!-- Template Group Add -->
      <div *ngIf="sidebarType === 'groupAdd'">
        <verde-group-add></verde-group-add>
      </div>

      <!-- Template Group View -->
      <div *ngIf="sidebarType === 'groupView'">
        <verde-group-view></verde-group-view>
      </div>

      <!-- Template Group Edit -->
      <div *ngIf="sidebarType === 'groupEdit'">
        <verde-group-edit></verde-group-edit>
      </div>

      <!-- Template Subgroup Add -->
      <div *ngIf="sidebarType === 'subgroupAdd'">
        <verde-subgroup-add></verde-subgroup-add>
      </div>

      <!-- Template Subgroup View -->
      <div *ngIf="sidebarType === 'subgroupView'">
        <verde-subgroup-view></verde-subgroup-view>
      </div>

      <!-- Template Subgroup Edit -->
      <div *ngIf="sidebarType === 'subgroupEdit'">
        <verde-subgroup-edit></verde-subgroup-edit>
      </div>

      <!-- Template View -->
      <div *ngIf="sidebarType === 'templateView'">
        <verde-template-view></verde-template-view>
      </div>

      <!-- Template Edit -->
      <div *ngIf="sidebarType === 'templateEdit'">
        <verde-template-edit></verde-template-edit>
      </div>

      <!-- Benchmark View -->
      <div *ngIf="sidebarType === 'benchmarkView'">
        <verde-benchmark-view></verde-benchmark-view>
      </div>

      <!-- Benchmark Edit -->
      <div *ngIf="sidebarType === 'benchmarkEdit'">
        <verde-benchmark-edit></verde-benchmark-edit>
      </div>

      <!-- Benchmark Add -->
      <div *ngIf="sidebarType === 'benchmarkAdd'">
        <verde-benchmark-add></verde-benchmark-add>
      </div>

      <!-- Personal details -->
      <div *ngIf="sidebarType === 'personal-details'">
        <verde-personal-details></verde-personal-details>
      </div>

      <!-- Payroll -->
      <div *ngIf="sidebarType === 'payroll'">
        <verde-payroll-upload></verde-payroll-upload>
      </div>

      <!-- Task -->
      <div *ngIf="sidebarType === 'viewtaskcomponent'">
        <view-task></view-task>
      </div>

      <!-- Personel Performance Review -->
      <div *ngIf="sidebarType === 'personalPerformanceReview'">
        <verde-enhanced-review
          (optionSelected)="captureReviewService.setQuestions($event)"
          (nextButtonClick)="captureReviewService.captureReview(false)"
          (reviewSubmit)="captureReviewService.captureReview()"
        ></verde-enhanced-review>
      </div>

      <!-- Peer Review -->
      <div *ngIf="sidebarType === 'peerReview'">
        <verde-review
          (nextButtonClick)="capturePeerReviewService.captureReview(false)"
          (reviewSubmit)="capturePeerReviewService.captureReview()"
          (optionSelected)="capturePeerReviewService.setQuestions($event)"
          [currentQuestion]="capturePeerReviewService.currentUnansweredQuestion"
        ></verde-review>
      </div>

      <!-- Fleet Request Item -->
      <div *ngIf="sidebarType === 'fleet-request-item'">
        <verde-fleet-request-item></verde-fleet-request-item>
      </div>

      <!-- Fleet Return Item -->
      <div *ngIf="sidebarType === 'fleet-return-item'">
        <verde-fleet-return-item></verde-fleet-return-item>
      </div>

      <!-- Fleet Entity Transfer -->
      <div *ngIf="sidebarType === 'fleet-entity-transfer'">
        <verde-fleet-entity-transfer></verde-fleet-entity-transfer>
      </div>

      <!-- Fleet Odometer Update -->
      <div *ngIf="sidebarType === 'fleet-odometer-update'">
        <verde-fleet-odo-update></verde-fleet-odo-update>
      </div>

      <!-- Qualifications -->
      <div *ngIf="sidebarType === 'qualifications'">
        <verde-qualifications></verde-qualifications>
      </div>

      <!-- verdeDynamicForm -->
      <div *ngIf="sidebarType === 'verdeDynamicForm'" style="height: 100%">
        <verde-dynamic-form-SidePanel style="height: inherit"></verde-dynamic-form-SidePanel>
      </div>

      <!-- Employee Exit -->
      <div *ngIf="sidebarType === 'employeeExit'">
        <verde-employee-exit></verde-employee-exit>
      </div>

      <!-- Employee Exit Edit-->
      <div *ngIf="sidebarType === 'employeeExitEdit'">
        <verde-employee-exit-edit></verde-employee-exit-edit>
      </div>

      <!-- Employee Onboarding-->
      <div *ngIf="sidebarType === 'employeeOnboarding'">
        <verde-employee-onboarding></verde-employee-onboarding>
      </div>

      <!-- Employee Onboarding Edit-->
      <div *ngIf="sidebarType === 'employeeOnboardingEdit'">
        <verde-employee-onboarding-edit></verde-employee-onboarding-edit>
      </div>

      <!-- Employee Onboarding Additional-->
      <div *ngIf="sidebarType === 'employeeOnboardingAdditional'">
        <verde-employee-onboarding-additional></verde-employee-onboarding-additional>
      </div>

      <!-- Opportunities -->
      <div *ngIf="sidebarType === 'newopportunity' || sidebarType === 'editopportunity'">
        <verde-opportunities></verde-opportunities>
      </div>

      <!-- Dynamic Form -->
      <div *ngIf="sidebarType === 'dynamic-form'">
        <verde-dynamic-form></verde-dynamic-form>
      </div>

      <!-- Structure Change Request -->
      <div *ngIf="sidebarType === 'structureChangeRequest'">
        <verde-structure-change-request></verde-structure-change-request>
      </div>

      <!-- Structure Dispose Request -->
      <div *ngIf="sidebarType === 'structureDisposeRequest'">
        <verde-structure-dispose-request></verde-structure-dispose-request>
      </div>

      <!-- Position Change Request -->
      <div *ngIf="sidebarType === 'positionChangeRequest'">
        <verde-position-change-request></verde-position-change-request>
      </div>
      <!-- Employee Resignation -->
      <div *ngIf="sidebarType === 'employeeResignation'">
        <verde-employee-resignation></verde-employee-resignation>
      </div>
      <!-- Bulk Upload -->
      <div *ngIf="sidebarType === 'bulkUpload'">
        <verde-bulk-upload></verde-bulk-upload>
      </div>
      <div *ngIf="sidebarType === 'bulkUploadAdmin'">
        <verde-bulk-upload-admin></verde-bulk-upload-admin>
      </div>
      <!-- Employee Resignation Edit -->
      <div *ngIf="sidebarType === 'employeeResignationEdit'">
        <verde-employee-resignation-edit></verde-employee-resignation-edit>
      </div>
      <!-- Employee Resignation View -->
      <div *ngIf="sidebarType === 'employeeResignationView'">
        <verde-employee-resignation-view></verde-employee-resignation-view>
      </div>

      <!-- Successor Details -->
      <div *ngIf="sidebarType === 'successor-details'">
        <verde-successor-details></verde-successor-details>
      </div>

      <!-- Booking Availability Search -->
      <div *ngIf="sidebarType === 'bookingAvailabilitySearch'">
        <booking-availability-search></booking-availability-search>
      </div>

      <!-- Booking Availability Results -->
      <div *ngIf="sidebarType === 'bookingAvailabilityResults'">
        <verde-booking-availability-results (setSidePanelTitleText)="setBookingResultsTitle($event)"></verde-booking-availability-results>
      </div>

      <!-- Graph Test -->
      <div *ngIf="sidebarType === 'verde-graph-test'" style="height: 100%">
        <verde-graph-test style="height: inherit"></verde-graph-test>
      </div>
    </div>

    <!-- footer -->
    <!--<div class="footer-sidebar">
    <div> {{ logGroup }}</div>
  </div>-->
  </div>

  <verde-approval-modal
    *ngIf="showVerdeApprovalModal"
    [approvalDefinitionHeader]="approvalDefinitionName"
    [logGroupID]="logGroupID"
    [logID]="logID"
    (hideModalEvent)="hideVerdeApprovalModal()"
  >
  </verde-approval-modal>

  <kendo-dialog *ngIf="showCancelModal" title="Are you sure?" verdeResponsiveDialog>
    <div style="height: 60px">
      <p>If you confirm now the process will cancel.</p>
    </div>
    <kendo-dialog-actions>
      <button kendoButton themeColor="primary" (click)="showCancelModal = false">Cancel</button>
      <button kendoButton themeColor="primary" style="margin-left: auto" (click)="confirmCancel()">Confirm</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
