import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DrawerItem } from '@progress/kendo-angular-layout';
import { UserAppConfigDto, UserUserDto, UserVerdeErrorType } from '@verde/api';
import { UserService, WindowService } from '@verde/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SideMenuDrawerItem } from '../models/side-menu-drawer-item';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {
  onDestroy$ = new Subject<boolean>();

  user: UserUserDto | undefined;
  dailyGreeting: string = '';
  greetingCardName: string | null | undefined = '';
  serviceAnniversaryMessage: string = '';
  greetingMessage: string = '';

  showBirthdayMessage: boolean = false;
  showServiceAnniversary: boolean = false;

  engageLabel: string | null = 'Risk';
  selected: string = '';

  // Config
  config: UserAppConfigDto | undefined;
  // onlineTrainingConfig?: VerdeOnlineTrainingConfig[];
  // onlineTrainingEmployee?: VerdeOnlineTraining | null;
  // showOnlineTrainingModal?: boolean = false;

  // UAT icons:
  //https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/uat/home.svg
  // Prod icons:
  //https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/home.svg

  items: Array<SideMenuDrawerItem> = [
    {
      drawerItem: {
        text: 'Home',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/home.svg',
        selected:
          window.location.pathname.toString() === '/home' || window.location.pathname.toString() === '/dashboard' || window.location.pathname.toString() === '/'
            ? true
            : false,
      },
      selector: 'bt_home',
      endPoint: '/home',
    },
    {
      drawerItem: {
        text: 'My Team',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/team.svg',
        selected: window.location.pathname.toString() === '/review' ? true : false,
      },
      selector: 'bt_myteam',
      endPoint: '/review',
    },
    {
      drawerItem: {
        text: 'Tasks',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/task.svg',
        selected: window.location.pathname.toString() === '/tasks' ? true : false,
      },
      selector: 'bt_tasks',
      endPoint: '/tasks',
    },
    {
      drawerItem: {
        text: 'Risk',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/engage.svg',
        selected: window.location.pathname.toString() === '/risk' ? true : false,
      },
      selector: 'bt_engage2',
      endPoint: '/risk',
    },
    {
      drawerItem: {
        text: 'Time Off',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/timeoff.svg',
        selected: window.location.pathname.toString() === '/timeoff' ? true : false,
      },
      selector: 'bt_timeoff',
      endPoint: '/timeoff',
    },
    {
      drawerItem: {
        text: 'Fleet',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/fleet.svg',
        selected:
          window.location.pathname.toString() === '/fleet' ||
          window.location.pathname.toString() === '/fleet/all-fleet' ||
          window.location.pathname.toString() === '/fleet/your-fleet' ||
          window.location.pathname.toString() === '/fleet/outstanding-movements' ||
          window.location.pathname.toString() === '/fleet/outstanding-monthly-checklists' ||
          window.location.pathname.toString() === '/fleet/movements-history' ||
          window.location.pathname.toString() === '/fleet/new-items' ||
          window.location.pathname.toString() === '/fleet/licenses' ||
          window.location.pathname.toString() === '/fleet/suppliers' ||
          window.location.pathname.toString() === '/fleet/models' ||
          window.location.pathname.toString() === '/fleet/classification-updates' ||
          window.location.pathname.toString() === '/fleet/action-required' ||
          window.location.pathname.toString().includes('/fleet/finished-checklists/') ||
          window.location.pathname.toString().includes('/fleet/fleet-checklist/') ||
          window.location.pathname.toString().includes('/fleet/fleet-item-history/')
            ? true
            : false,
      },
      selector: 'bt_fleet',
      endPoint: '/fleet',
    },
    {
      drawerItem: {
        text: 'Reviews',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/reviews.svg',
        selected: window.location.pathname.toString() === '/performance-review' ? true : false,
      },
      selector: 'bt_performancereview',
      endPoint: '/performance-review',
    },
    {
      drawerItem: {
        text: 'Voting',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/vote.svg',
        selected: window.location.pathname.toString() === '/profile/voting' ? true : false,
      },
      selector: 'bt_voting',
      endPoint: '/profile/voting',
    },
    {
      drawerItem: {
        text: 'Requests',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/requests.svg',
        selected: window.location.pathname.toString() === '/requests' ? true : false,
      },
      selector: 'bt_requests',
      endPoint: '/requests',
    },
    {
      drawerItem: {
        text: 'Bookings',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/bookings.svg',
        selected: window.location.pathname.toString() === '/bookings' ? true : false,
      },
      selector: 'bt_bookings',
      endPoint: '/bookings',
    },
    {
      drawerItem: {
        text: 'Time Tracking',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/timetracker.svg',
        selected: window.location.pathname.toString() === '/time-tracking' ? true : false,
      },
      selector: 'bt_timetracking',
      endPoint: '/time-tracking',
    },
    {
      drawerItem: {
        text: 'Structure Management',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/structure.svg',
        selected: window.location.pathname.toString() === '/structure' ? true : false,
      },
      selector: 'bt_structure',
      endPoint: '/structure',
    },
    {
      drawerItem: {
        text: 'Policy',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/policy.svg',
        selected: window.location.pathname.toString() === '/policy' ? true : false,
      },
      selector: 'bt_policy',
      endPoint: '/policy',
    },
    {
      drawerItem: {
        text: 'Documents and Forms',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/policy.svg',
        selected: window.location.pathname.toString() === '/policy/documents' ? true : false,
      },
      selector: 'bt_policydocuments',
      endPoint: '/policy/documents',
    },
    {
      drawerItem: {
        text: 'Delegation',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/delegation.svg',
        selected: window.location.pathname.toString() === '/delegation' ? true : false,
      },
      selector: 'bt_delegation',
      endPoint: '/delegation',
    },
    {
      drawerItem: {
        text: 'Documents',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/documents.svg',
        selected: window.location.pathname.toString() === '/document' ? true : false,
      },
      selector: 'bt_document',
      endPoint: '/document',
    },
    {
      drawerItem: {
        text: 'Equipment',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/equipment.svg',
        selected:
          window.location.pathname.toString() === '/equipment/my-equipment' ||
          window.location.pathname.toString() === '/equipment/active-requests' ||
          window.location.pathname.toString() === '/equipment/historical-requests' ||
          window.location.pathname.toString() === '/equipment/request-allocation' ||
          window.location.pathname.toString() === '/equipment/request-stock-items' ||
          window.location.pathname.toString() === '/equipment/take-on' ||
          window.location.pathname.toString().includes('/equipment/request/')
            ? true
            : false,
      },
      selector: 'bt_equipment',
      endPoint: '/equipment',
    },
    {
      drawerItem: {
        text: 'Gifts',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/gift.svg',
        selected: window.location.pathname.toString() === '/gifts' ? true : false,
      },
      selector: 'bt_gifts',
      endPoint: '/gifts',
    },
    {
      drawerItem: {
        text: 'Support',
        icon: 'https://engageimages.z1.web.core.windows.net/Images/Verde/Menu/default/support.svg',
        selected: window.location.pathname.toString() === '/support' ? true : false,
      },
      selector: 'bt_support',
      endPoint: '/support',
    },
    {
      drawerItem: {
        text: 'Communication',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/mail.svg',
        selected: window.location.pathname.toString() === '/communication' ? true : false,
      },
      selector: 'bt_communication',
      endPoint: '/communication',
    },
    {
      drawerItem: {
        text: 'Help Desk',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/support.svg',
        selected: window.location.pathname.toString() === '/help-desk' ? true : false,
      },
      selector: 'bt_helpdesk',
      endPoint: '/help-desk',
    },
    {
      drawerItem: {
        text: 'People',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/profile.svg',
        selected: window.location.pathname.toString() === '/people' ? true : false,
      },
      selector: 'bt_people',
      endPoint: '/people',
    },
    {
      drawerItem: {
        text: 'Profile',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/profile.svg',
        selected: window.location.pathname.toString() === '/profile' ? true : false,
      },
      selector: 'bt_profile',
      endPoint: '/profile',
    },
    {
      drawerItem: {
        text: 'My Profile',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/profile.svg',
        selected: window.location.pathname.toString() === '/my-profile' ? true : false,
      },
      selector: 'bt_myprofile',
      endPoint: '/my-profile',
    },
    {
      drawerItem: {
        text: 'Learn',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/training.svg',
        selected: window.location.pathname.toString() === '/learn' ? true : false,
      },
      selector: 'bt_learn',
      endPoint: '/learn',
    },
    {
      drawerItem: {
        text: 'Info',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/info.svg',
        selected: window.location.pathname.toString() === '/info' ? true : false,
      },
      selector: 'bt_info',
      endPoint: '/info',
    },
    {
      drawerItem: {
        text: 'Dynamic Form',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/info.svg',
        selected: window.location.pathname.toString() === '/form' ? true : false,
      },
      selector: 'bt_form',
      endPoint: '/form',
    },
    {
      drawerItem: {
        text: 'Issues',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/info.svg',
        selected: window.location.pathname.toString() === '/verde-issue' ? true : false,
      },
      selector: 'bt_verdeissue',
      endPoint: '/verde-issue',
    },
    {
      drawerItem: {
        text: 'People Management',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/info.svg',
        selected: window.location.pathname.toString() === '/hr-portal' ? true : false,
      },
      selector: 'bt_hrportal',
      endPoint: '/hr-portal',
    },

    {
      drawerItem: {
        text: 'Opportunities',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/info.svg',
        selected: window.location.pathname.toString() === '/opportunities' ? true : false,
      },
      selector: 'bt_opportunities',
      endPoint: '/opportunities',
    },

    {
      drawerItem: {
        text: 'My Calendar',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/info.svg',
        selected: window.location.pathname.toString() === '/my_calendar' ? true : false,
      },
      selector: 'my_calendar',
      endPoint: '/my_calendar',
    },

    {
      drawerItem: {
        text: 'Graph Test',
        icon: 'https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/info.svg',
        selected: window.location.pathname.toString() === '/graph_test' ? true : false,
      },
      selector: 'graph_test',
      endPoint: '/graph_test',
    },
  ];

  constructor(private router: Router, private windowService: WindowService, public userService: UserService) {
    this.userService.proxyUser$.pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      // initial tasks
      this.user = user;

      if (this.user?.employeeId != null) {
        if (this.user?.preferredName) {
          this.greetingCardName = this.user?.preferredName;
        } else {
          this.greetingCardName = this.user?.firstName;
        }

        this.determineTime();
        this.determineBirthday();
        this.determineServiceAnniversary();
      }
    });

    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      this.config = config;

      if (this.config?.verdeEnvironmentConfig?.verdeOnline) {
        this.initNavigation();
      }

      if (
        this.config?.legalEntityConfig?.engageAka !== null &&
        this.config?.legalEntityConfig?.engageAka !== undefined &&
        this.config?.legalEntityConfig?.engageAka !== ''
      ) {
        this.engageLabel = this.config?.legalEntityConfig?.engageAka;

        this.items.forEach((t) => {
          if (t.selector === 'bt_engage2') {
            t.drawerItem.text = this.config?.legalEntityConfig?.engageAka!;
          }
        });
      }
    });

    // Back Button click logic
    router.events.pipe(takeUntil(this.onDestroy$)).subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        // Perform actions
        this.items.forEach((t) => {
          if (t.endPoint === event.url) {
            this.onSelect(t?.drawerItem);
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  initNavigation() {
    if (this.config?.verdeEnvironmentConfig?.verdeOnline && !this.config?.verdeEnvironmentConfig?.verdeError) {
      //manager menu item
      this.items.forEach((t, index) => {
        if (t.selector === 'bt_myteam' && this.user && (this.user?.hasSubordinates === false || this.user?.hasSubordinates == null)) {
          this.items.splice(index, 1);
        }
      });

      //#region vote manager menu item
      let voteEnabledFlag = false;

      this.items.forEach((t, index) => {
        if (t.selector === 'bt_voting' && (this.config?.legalEntityConfig?.enableVoting === false || this.config?.legalEntityConfig?.enableVoting === null)) {
          this.items.splice(index, 1);
        } else {
          voteEnabledFlag = true;
        }
      });

      if (voteEnabledFlag) {
        let voteManagerFlag = false;

        if (this.userService.isPermissionsLoaded) {
          voteManagerFlag = this.userService.userHasPermission('PEOPLE-VOTE-MANAGER');
        }

        if (!voteManagerFlag) {
          this.items.forEach((t, index) => {
            if (t.selector === 'bt_voting') {
              this.items.splice(index, 1);
            }
          });
        }
      }

      //#endregion

      //#region structure menu item
      let structureEnabledFlag = false;

      this.items.forEach((t, index) => {
        if (
          t.selector === 'bt_structure' &&
          (this.config?.legalEntityConfig?.structureManagement === false || this.config?.legalEntityConfig?.structureManagement === null)
        ) {
          this.items.splice(index, 1);
        } else {
          structureEnabledFlag = true;
        }
      });

      if (structureEnabledFlag) {
        let structureAdminFlag = false;

        if (this.config?.verdeEnvironmentConfig && this.userService.isPermissionsLoaded) {
          structureAdminFlag = this.userService.userHasPermission('STRUCTURE-STRUCTURE-ADMIN');
        }

        if (!structureAdminFlag) {
          this.items.forEach((t, index) => {
            if (t.selector === 'bt_structure') {
              this.items.splice(index, 1);
            }
          });
        }
      }

      // Employee Config Related Navigations
      this.items.forEach((t, index) => {
        if (t.selector === 'bt_myteam' && (this.config?.legalEntityConfig?.reviews === false || this.config?.legalEntityConfig?.reviews === null)) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (t.selector === 'bt_profile' && (this.config?.legalEntityConfig?.profile === false || this.config?.legalEntityConfig?.profile === null)) {
          this.items.splice(index, 1);

          if (window.location.pathname.toString() === '/') {
            this.onSelect(this.items.filter((i) => i.selector === 'bt_engage')[0]?.drawerItem);
            this.selected = 'Engage';
          }
        }
      });

      this.items.forEach((t, index) => {
        if (t.selector === 'bt_gifts' && (this.config?.legalEntityConfig?.gifts === false || this.config?.legalEntityConfig?.gifts === null)) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (t.selector === 'bt_engage2' && (this.config?.legalEntityConfig?.engage === false || this.config?.legalEntityConfig?.engage === null)) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (t.selector === 'bt_timeoff' && (this.config?.legalEntityConfig?.timeOff === false || this.config?.legalEntityConfig?.timeOff === null)) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (t.selector === 'bt_equipment' && (this.config?.legalEntityConfig?.equipment === false || this.config?.legalEntityConfig?.equipment === null)) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (t.selector === 'bt_fleet' && (this.config?.legalEntityConfig?.vehicles === false || this.config?.legalEntityConfig?.vehicles === null)) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (
          t.selector === 'bt_performancereview' &&
          (this.config?.legalEntityConfig?.reviewsEnhancement === false || this.config?.legalEntityConfig?.reviewsEnhancement === null)
        ) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (
          t.selector === 'bt_requests' &&
          (this.config?.legalEntityConfig?.enableRequests === false || this.config?.legalEntityConfig?.enableRequests === null)
        ) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (t.selector === 'bt_tasks' && (this.config?.legalEntityConfig?.tasks === false || this.config?.legalEntityConfig?.tasks === null)) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (t.selector === 'bt_bookings' && (this.config?.legalEntityConfig?.bookings === false || this.config?.legalEntityConfig?.bookings === null)) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (
          t.selector === 'bt_timetracking' &&
          (this.config?.legalEntityConfig?.timeTracker === false || this.config?.legalEntityConfig?.timeTracker === null)
        ) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (
          t.selector === 'bt_policy' &&
          (this.config?.legalEntityConfig?.enablePolicies === false || this.config?.legalEntityConfig?.enablePolicies === null)
        ) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (
          t.selector === 'bt_document' &&
          (this.config?.legalEntityConfig?.documentManagement === false || this.config?.legalEntityConfig?.documentManagement === null)
        ) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (
          t.selector === 'bt_delegation' &&
          (this.config?.legalEntityConfig?.allowManagerDelegation === false || this.config?.legalEntityConfig?.allowManagerDelegation === null)
        ) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (
          t.selector === 'bt_communication' &&
          (this.config?.legalEntityConfig?.communication === false || this.config?.legalEntityConfig?.communication === null)
        ) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (t.selector === 'bt_helpdesk' && (this.config?.legalEntityConfig?.support === false || this.config?.legalEntityConfig?.support === null)) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (t.selector === 'bt_people' && (this.config?.legalEntityConfig?.people === false || this.config?.legalEntityConfig?.people === null)) {
          this.items.splice(index, 1);
        }
      });

      this.items.forEach((t, index) => {
        if (t.selector === 'bt_learn' && (this.config?.legalEntityConfig?.learning === false || this.config?.legalEntityConfig?.learning === null)) {
          this.items.splice(index, 1);
        }
      });
    }
  }

  // TODO: continue applying new spinner logic for other pages
  onSelect(e: any, params?: string, dynamicFormName?: string): void {
    this.setSelectedIcon(e);
    this.selected = e.text;

    //new home page message logic
    if (e.text === 'Home' || e.text === 'Dashboard') {
      this.selected = this.greetingMessage;
    }

    if (params === undefined) {
      // NO PARAMS
      if (this.selected === this.greetingMessage) {
        this.router.navigate(['/home']);
      } else if (this.selected === 'Time Off') {
        this.router.navigate(['/timeoff']);
      } else if (this.selected === 'Gifts') {
        this.router.navigate(['/gifts']);
      } else if (this.selected === 'Engage Old') {
        this.router.navigate(['/engageold']);
      } else if (this.selected === this.engageLabel) {
        this.router.navigate(['/risk']);
      } else if (this.selected === 'My Team') {
        this.router.navigate(['/review']);
      } else if (this.selected === 'Equipment') {
        if (this.config?.legalEntityConfig?.equipmentTakeOn) {
          this.router.navigate(['/equipment', 'take-on']);
        } else {
          this.router.navigate(['/equipment', 'my-equipment']);
        }
      } else if (this.selected === 'Fleet') {
        this.router.navigate(['/fleet']);
      } else if (this.selected === 'Reviews') {
        this.router.navigate(['/performance-review']);
      } else if (this.selected === 'Info') {
        this.router.navigate(['/info']);
      } else if (this.selected === 'Profile') {
        this.router.navigate(['/profile']);
      } else if (this.selected === 'My Profile') {
        this.router.navigate(['/my-profile']);
      } else if (this.selected === 'Bookings') {
        this.router.navigate(['/bookings']);
      } else if (this.selected === 'Learn') {
        this.router.navigate(['/learn']);
      } else if (this.selected === 'Requests') {
        this.router.navigate(['/requests']);
      } else if (this.selected === 'Voting') {
        this.router.navigate(['/profile/votemanagement']);
      } else if (this.selected === 'Tasks') {
        this.router.navigate(['/tasks']);
      } else if (this.selected === 'Time Tracking') {
        this.router.navigate(['/time-tracking']);
      } else if (this.selected === 'Policy') {
        this.router.navigate(['/policy']);
      } else if (this.selected === 'Documents and Forms') {
        this.router.navigate(['/policy/documents']);
      } else if (this.selected === 'Documents') {
        this.router.navigate(['/document']);
      } else if (this.selected === 'Structure Management') {
        this.router.navigate(['/structure']);
      } else if (this.selected === 'Delegation') {
        this.router.navigate(['/delegation']);
      } else if (this.selected === 'Support') {
        this.router.navigate(['/support']);
      } else if (this.selected === 'Communication') {
        this.router.navigate(['/communication']);
      } else if (this.selected === 'Help Desk') {
        this.router.navigate(['/help-desk']);
      } else if (this.selected === 'People') {
        this.router.navigate(['/people']);
      } else if (this.selected === dynamicFormName) {
        this.router.navigate(['/form']);
      } else if (this.selected === 'Issues') {
        this.router.navigate(['/verde-issue']);
      } else if (this.selected === 'My Calendar') {
        this.router.navigate(['/my_calendar']);
        //} else if (this.selected === 'Graph Test') {
      } else if (this.selected === 'People Management') {
        this.router.navigate(['/hr-portal']);
      } else if (this.selected === 'Opportunities') {
        this.router.navigate(['/opportunities']);
      }
    } else {
      // HAS PARAMS
      if (this.selected === this.greetingMessage) {
        this.router.navigate(['/home', params]);
      } else if (this.selected === 'Time Off') {
        this.router.navigate(['/timeoff', params]);
      } else if (this.selected === 'Gifts') {
        this.router.navigate(['/gifts', params]);
      } else if (this.selected === 'Engage Old') {
        this.router.navigate(['/engageold', params]);
      } else if (this.selected === this.engageLabel) {
        this.router.navigate(['/risk', params]);
      } else if (this.selected === 'My Team') {
        this.router.navigate(['/review', params]);
      } else if (this.selected === 'Equipment') {
        this.router.navigate(['/equipment', params]);
      } else if (this.selected === 'Fleet') {
        this.router.navigate(['/fleet', params]);
      } else if (this.selected === 'Reviews') {
        this.router.navigate(['/performance-review', params]);
      } else if (this.selected === 'Info') {
        this.router.navigate(['/info', params]);
      } else if (this.selected === 'Profile') {
        this.router.navigate(['/profile', params]);
      } else if (this.selected === 'My Profile') {
        this.router.navigate(['/my-profile', params]);
      } else if (this.selected === 'Bookings') {
        this.router.navigate(['/bookings', params]);
      } else if (this.selected === 'Learn') {
        this.router.navigate(['/learn', params]);
      } else if (this.selected === 'Requests') {
        this.router.navigate(['/requests', params]);
      } else if (this.selected === 'Voting') {
        this.router.navigate(['/profile/votemanagement']);
      } else if (this.selected === 'Tasks') {
        this.router.navigate(['/tasks', params]);
      } else if (this.selected === 'Time Tracking') {
        this.router.navigate(['/time-tracking', params]);
      } else if (this.selected === 'Policy') {
        this.router.navigate(['/policy', params]);
      } else if (this.selected === 'Documents and Forms') {
        this.router.navigate(['/policy/documents', params]);
      } else if (this.selected === 'Documents') {
        this.router.navigate(['/document', params]);
      } else if (this.selected === 'Structure Management') {
        this.router.navigate(['/structure', params]);
      } else if (this.selected === 'Delegation') {
        this.router.navigate(['/delegation', params]);
      } else if (this.selected === 'Support') {
        this.router.navigate(['/support', params]);
      } else if (this.selected === 'Communication') {
        this.router.navigate(['/communication', params]);
      } else if (this.selected === 'Help Desk') {
        this.router.navigate(['/help-desk', params]);
      } else if (this.selected === 'People') {
        this.router.navigate(['/people', params]);
      } else if (this.selected === dynamicFormName) {
        this.router.navigate(['/form', params]);
      } else if (this.selected === 'Issues') {
        this.router.navigate(['/verde-issue', params]);
      } else if (this.selected === 'People Management') {
        this.router.navigate(['/hr-portal', params]);
      } else if (this.selected === 'My Calendar') {
        this.router.navigate(['/my_calendar', params]);
        //      } else if (this.selected === 'Graph Test') {
        //console.log("GraphTest has params")
      } else if (this.selected === 'Opportunities') {
        this.router.navigate(['/opportunities', params]);
      }
    }
  }

  determineSelected() {
    if (
      window.location.pathname.toString() === '/home' ||
      window.location.pathname.toString() === '/' ||
      window.location.pathname.toString() === '/dashboard'
    ) {
      this.selected = this.greetingMessage;
    } else if (window.location.pathname.toString() === '/profile') {
      this.selected = 'Profile';
    } else if (window.location.pathname.toString() === '/my-profile') {
      this.selected = 'My Profile';
    } else if (window.location.pathname.toString() === '/timeoff') {
      this.selected = 'Time Off';
    } else if (window.location.pathname.toString() === '/gifts') {
      this.selected = 'Gifts';
    } else if (window.location.pathname.toString() === '/review') {
      this.selected = 'My Team';
    } else if (window.location.pathname.toString() === '/engageold') {
      this.selected = 'Engage Old';
    } else if (window.location.pathname.toString() === '/risk') {
      this.selected = this.engageLabel!;
    } else if (
      window.location.pathname.toString() === '/equipment/my-equipment' ||
      window.location.pathname.toString() === '/equipment/active-requests' ||
      window.location.pathname.toString() === '/equipment/historical-requests' ||
      window.location.pathname.toString() === '/equipment/request-allocation' ||
      window.location.pathname.toString() === '/equipment/request-stock-items' ||
      window.location.pathname.toString() === '/equipment/take-on' ||
      window.location.pathname.toString().includes('/equipment/request/')
    ) {
      this.selected = 'Equipment';
    } else if (
      window.location.pathname.toString() === '/fleet' ||
      window.location.pathname.toString() === '/fleet/all-fleet' ||
      window.location.pathname.toString() === '/fleet/your-fleet' ||
      window.location.pathname.toString() === '/fleet/outstanding-movements' ||
      window.location.pathname.toString() === '/fleet/outstanding-monthly-checklists' ||
      window.location.pathname.toString() === '/fleet/movements-history' ||
      window.location.pathname.toString() === '/fleet/new-items' ||
      window.location.pathname.toString() === '/fleet/licenses' ||
      window.location.pathname.toString() === '/fleet/suppliers' ||
      window.location.pathname.toString() === '/fleet/models' ||
      window.location.pathname.toString() === '/fleet/classification-updates' ||
      window.location.pathname.toString().includes('/fleet/finished-checklists/') ||
      window.location.pathname.toString().includes('/fleet/fleet-checklist/') ||
      window.location.pathname.toString().includes('/fleet/fleet-item-history/')
    ) {
      this.selected = 'Fleet';
    } else if (window.location.pathname.toString() === '/performance-review') {
      this.selected = 'Performance review';
    } else if (window.location.pathname.toString() === '/info') {
      this.selected = 'Info';
    } else if (window.location.pathname.toString() === '/bookings') {
      this.selected = 'Bookings';
    } else if (window.location.pathname.toString() === '/requests') {
      this.selected = 'Requests';
    } else if (window.location.pathname.toString() === '/profile/votemanagement') {
      this.selected = 'Voting';
    } else if (window.location.pathname.toString() === '/time-tracking') {
      this.selected = 'Time Tracking';
    } else if (window.location.pathname.toString() === '/learn') {
      this.selected = 'Learn';
    } else if (window.location.pathname.toString() === '/policy') {
      this.selected = 'Policy';
    } else if (window.location.pathname.toString() === '/policy/documents') {
      this.selected = 'Documents and Forms';
    } else if (window.location.pathname.toString() === '/document') {
      this.selected = 'Documents';
    } else if (window.location.pathname.toString() === '/structure') {
      this.selected = 'Structure Management';
    } else if (window.location.pathname.toString() === '/delegation') {
      this.selected = 'Delegation';
    } else if (window.location.pathname.toString() === '/support') {
      this.selected = 'Support';
    } else if (window.location.pathname.toString() === '/communication') {
      this.selected = 'Communication';
    } else if (window.location.pathname.toString() === '/help-desk') {
      this.selected = 'Help Desk';
    } else if (window.location.pathname.toString() === '/people') {
      this.selected = 'People';
    } else if (window.location.pathname.toString() === '/form') {
      this.selected = 'Dynamic Form';
    } else if (window.location.pathname.toString() === '/verde-issue') {
      this.selected = 'Issues';
    } else if (window.location.pathname.toString() === '/hr-portal') {
      this.selected = 'People Management';
    } else if (window.location.pathname.toString() === '/my_calendar') {
      this.selected = 'My Calendar';
    } else if (window.location.pathname.toString() === '/graph_test') {
      this.selected = 'Graph Test';
    } else if (window.location.pathname.toString() === '/opportunities') {
      this.selected = 'Opportunities';
    }
  }

  determineTime() {
    const format = 'hh:mm:ss';
    const now = moment();
    const morningStart = moment('06:00:00', format);
    const morningEnd = moment('11:59:59', format);
    const afternoonStart = moment('12:00:00', format);
    const afternoonEnd = moment('17:59:59', format);
    const eveningStart = moment('18:00:00', format);
    const eveningEnd = moment('05:59:59', format);

    if (now.isBetween(morningStart, morningEnd)) {
      this.dailyGreeting = 'morning';
    } else if (now.isBetween(afternoonStart, afternoonEnd)) {
      this.dailyGreeting = 'afternoon';
    } else if (now.isBetween(eveningStart, eveningEnd)) {
      this.dailyGreeting = 'evening';
    } else {
      this.dailyGreeting = 'day';
    }
  }

  determineServiceAnniversary() {
    const today = moment();
    const startDate = moment(this.user?.startDate);

    if (startDate === today) {
      this.serviceAnniversaryMessage = 'Welcome on your first day, ' + this.greetingCardName;
      this.showServiceAnniversary = true;
    } else if (
      today.dayOfYear() === startDate.dayOfYear() &&
      today.month() === startDate.month() &&
      this.user?.yearsAtEntity &&
      this.user?.yearsAtEntity >= 1
    ) {
      this.serviceAnniversaryMessage = 'Congratulations on celebrating ' + this.user?.yearsAtEntity + ' year(s) of Service, ' + this.greetingCardName;
      this.showServiceAnniversary = true;
    } else {
      this.showServiceAnniversary = false;
    }

    this.determineGreetingMessage();
  }

  determineBirthday() {
    const today = moment();
    const dateOfBirth = moment(this.user?.dateOfBirth);

    if (today.dayOfYear() === dateOfBirth.dayOfYear() && today.month() === dateOfBirth.month()) {
      this.showBirthdayMessage = true;
    } else {
      this.showBirthdayMessage = false;
    }

    this.determineGreetingMessage();
  }

  determineGreetingMessage() {
    if (!this.showBirthdayMessage && !this.showServiceAnniversary) {
      this.greetingMessage = 'Good ' + this.dailyGreeting + ', ' + this.greetingCardName;
    } else if (this.showBirthdayMessage && !this.showServiceAnniversary) {
      this.greetingMessage = 'Happy Birthday,  ' + this.greetingCardName;
    } else if (!this.showBirthdayMessage && this.showServiceAnniversary) {
      this.greetingMessage = this.serviceAnniversaryMessage;
    }

    if (window.location.pathname.toString() === '/dashboard' || window.location.pathname.toString() === '/' || window.location.pathname.toString() === 'home') {
      this.selected = this.greetingMessage;
    }
  }

  getDrawerItemsFromItems(): DrawerItem[] {
    return this.items.map((item) => item?.drawerItem);
  }

  getDrawerMode(): 'overlay' | 'push' {
    return this.windowService.isMobile() ? 'overlay' : 'push';
  }

  //Private Declarations
  private setSelectedIcon(e: any): void {
    for (const item of this.items) {
      item.drawerItem.selected = false;
    }
    e.selected = true;
  }

  // loadOnlineTraining() {
  //   this.sharedApiTrainingLinksService
  //     .getOnlineTrainingConfig({
  //       legalEntityID: this.proxyUser$.getValue()?.legalEntityId ?? '',
  //     })
  //     .pipe(take(1))
  //     .subscribe(
  //       (ret) => {
  //         this.authState$.next({
  //           ...this.authState$.getValue(),
  //           onlineTrainingConfig: ret,
  //         });
  //       },
  //       (error) => {
  //         console.error(error);
  //       },
  //       () => {
  //         // add logic on for modules as now we assume there is only one record returned
  //         const onlineConfig = this.authState$.getValue().onlineTrainingConfig ?? [];
  //         if (onlineConfig.length > 0) {
  //           this.sharedApiTrainingLinksService
  //             .getOnlineTrainingEmployee({
  //               configID: onlineConfig[0].bt_verdeonlinetrainingconfigid ?? '',
  //               employeeID: this.userService.user?.employeeId ?? '',
  //               legalEntityID: this.userService.user?.legalEntityId ?? '',
  //             })
  //             // .pipe(takeUntil(this.onDestroy$))
  //             .subscribe(
  //               (ret) => {
  //                 if (ret.length > 0) {
  //                   this.authState$.next({
  //                     ...this.authState$.getValue(),
  //                     onlineTrainingEmployee: ret[0],
  //                   });
  //                 } else {
  //                   this.authState$.next({
  //                     ...this.authState$.getValue(),
  //                     onlineTrainingEmployee: null,
  //                   });
  //                 }
  //               },
  //               (error) => {
  //                 console.error(error);
  //               },
  //               () => {
  //                 if (this.authState$.getValue().onlineTrainingEmployee === null && onlineConfig[0].bt_autoload) {
  //                   this.authState$.next({
  //                     ...this.authState$.getValue(),
  //                     showOnlineTrainingModal: true,
  //                   });
  //                 }
  //               },
  //             );
  //         }
  //       },
  //     );
  // }
}
