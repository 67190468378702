import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'verde-kendo-formly-object',
  template: `<h6 *ngIf="props.label">{{ props.label }}</h6>
    <hr *ngIf="props.label" />
    <kendo-label *ngIf="props.description">{{ props.description }}</kendo-label>
    <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors"></div>
    <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>`,
  styleUrls: ['./object.type.scss'],
})
export class KendoFormlyObject extends FieldType {}
